import { Box, Flex, Text } from '@napa/grape-ui-react';
import React from 'react';
import styled from 'styled-components';

const BannerSection = styled('section')`
  background-image: url(${process.env.PUBLIC_URL}/assets/images/barrels.jpg);
  background-size: cover;
  margin-bottom: 10px;
`;

const BannerOverlay = styled('div')`
  background-color: rgba(128,23,71, 0.75);
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
`;

const PageTitle = styled(Text)`
  text-transform: uppercase;
  color: #fff;
  font-size: calc(32px + 6 * ((100vw - 320px) / 680));
  font-weight: bold;
`;

interface HeaderProps {
  pageTitle?: string;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { pageTitle } = props;
  return (
    <BannerSection>
      <BannerOverlay>
        <Flex justifyContent="center">
          <Box alignSelf="center">
            <PageTitle>{pageTitle}</PageTitle>
          </Box>
        </Flex>
      </BannerOverlay>
    </BannerSection>
  );
}

export default Header;
