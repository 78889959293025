import { useQueryString } from '@napa/react-core';
import { GrapeButton } from 'napa-react-core';
import React from 'react';

export default function NotFoundContainer(): JSX.Element {
  const qs = useQueryString();
  return (
    <div>
      <h1>Oops!</h1>
      <p>We can&apos;t seem to find the page you&apos;re looking for.</p>
      <GrapeButton routerLink={qs.get('referrer') || '/'}>Go Back</GrapeButton>
    </div>
  );
}
