import { GrapeButton, useModal } from 'napa-react-core';
import Header from 'components/baseElements/Header';
import { Loadable, Loaded, Unloaded } from 'components/baseElements/loadable';
import UserList from 'components/users/UserBrowse/UserList';
import useBrowseUsers from 'customHooks/users/useBrowseUsers';
import { Box, Flex, Text } from '@napa/grape-ui-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import RiseLoader from 'react-spinners/RiseLoader';
import styled from 'styled-components';
import { User } from 'types/users';
import CreateUserContainer from '../CreateUserContainer';
import UserDetailsContainer from '../UserDetailsContainer';

const UserBox = styled(Box)`
  width: 90%;
  flex: 1;
  margin-bottom: 70px;
`;

export default function BrowseInventoryContainer(): JSX.Element {
  const { data: userItems, isLoading: fetchIsLoading } = useBrowseUsers();
  const queryClient = useQueryClient();
  const { showModal, hideModal } = useModal();

  const handleUserSave = async (): Promise<void> => {
    await queryClient.invalidateQueries('browseUsers');
    hideModal();
  };
  const handleUserClick = (user: User) => {
    showModal({
      title: (
        <div>
          <FormattedMessage id="userDetailsForm.titleLabel" />
        </div>
      ),
      body: <UserDetailsContainer successHandler={() => handleUserSave()} userId={user.id || ''} />,
    });
  };
  return (
    <Flex justifyContent="center" flexDirection="column" height="100%">
      <Header pageTitle="Users" />
      <UserBox alignSelf="center">
        <Loadable isLoading={fetchIsLoading}>
          <Unloaded>
            <Flex flexDirection="column">
              <Box
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
                pt="150px"
                pb="50px"
              >
                <RiseLoader size={50} color={'rgb(128,23,71)'} loading={true} />
              </Box>
              <Box alignSelf="center" alignItems="center" justifyContent="center">
                <Text fontWeight="bold" fontSize="28pt">
                  Loading Users
                </Text>
              </Box>
            </Flex>
          </Unloaded>
          <Loaded>
            <Flex justifyContent="flex-end">
              <GrapeButton
                onClick={() =>
                  showModal({
                    title: (
                      <div>
                        <FormattedMessage id="CreateNewUser.createNewUserFormTitle" />
                      </div>
                    ),
                    body: <CreateUserContainer successHandler={() => handleUserSave()} />,
                  })
                }
              >
                <FormattedMessage id="CreateNewUser.createUserButtonLabel" />
              </GrapeButton>
            </Flex>
            <UserList userClickHandler={handleUserClick} userItems={userItems?.items} />
          </Loaded>
        </Loadable>
      </UserBox>
    </Flex>
  );
}
