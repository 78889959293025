import * as React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import { color, layout, LayoutProps, ResponsiveValue, space, SpaceProps, system, typography, TypographyProps } from 'styled-system';

const StyledCaption = styled('p')`
  ${color}
  ${layout}
  ${space}
  ${typography}
  ${system({ textTransform: true })}
`;

interface CaptionPropTypes extends React.HTMLAttributes<HTMLParagraphElement>, LayoutProps, SpaceProps, TypographyProps {
  textTransform?: ResponsiveValue<CSS.Property.TextTransform>,
}

export function Caption(props: CaptionPropTypes): JSX.Element {
  const {
    children,
    color = '#3B2827 !important',  // ionic made me do this
    fontSize = '10px !important',  // ionic made me do this
    fontWeight = 600,
    letterSpacing = '1px !important',  // ionic made me do this
    textTransform = 'uppercase',
    ...otherProps
} = props;
  return (
    <StyledCaption
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      textTransform={textTransform}
      {...otherProps}
    >
      {children}
    </StyledCaption>
  )
}
