import { yupResolver } from '@hookform/resolvers/yup';
import { IonInput, IonList } from '@ionic/react';
import { handleServerHookForm } from '@napa/react-core';
import { GrapeButton } from 'napa-react-core';
import HookInput from 'components/baseElements/formControls/HookInput';
import { Box } from 'components/baseElements/grid';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface ChangePasswordFormWebProperties {
  submitAction: (data: any) => any;
  changePasswordIsSaving?: boolean;
  serverValidation?: any;
  validationSchema?: any;
  showExistingPassword: boolean;
}

export default function ChangePasswordFormWeb(props: ChangePasswordFormWebProperties): JSX.Element {
  const { submitAction, serverValidation, showExistingPassword, validationSchema } = props;

  const formRef = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      passwordConfirm: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleServerHookForm(serverValidation, formRef.setError);
  }, [serverValidation, formRef.setError]);

  return (
    <Box maxWidth={600} mx="auto" width={1}>
      <form onSubmit={formRef.handleSubmit(submitAction)} noValidate>
        <IonList lines="full">
          {showExistingPassword ? (
            <HookInput
              form={formRef}
              isRequired
              labelText="changePasswordForm.currentPasswordLabel"
              name="currentPassword"
            >
              <IonInput clearOnEdit={false} type="password" />
            </HookInput>
          ) : undefined}
          <HookInput
            assistiveText="changePasswordForm.passwordAssistiveText"
            form={formRef}
            isRequired
            labelText="changePasswordForm.newPasswordLabel"
            name="newPassword"
          >
            <IonInput clearOnEdit={false} type="password" />
          </HookInput>
          <HookInput
            form={formRef}
            isRequired
            labelText="changePasswordForm.passwordConfirmLabel"
            name="passwordConfirm"
          >
            <IonInput clearOnEdit={false} type="password" />
          </HookInput>
        </IonList>
        <GrapeButton expand="block" type="submit">
          <FormattedMessage id="changePasswordForm.saveButtonLabel" />
        </GrapeButton>
      </form>
    </Box>
  );
}
