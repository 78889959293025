import React from 'react';
import ServicesOffered from 'components/marketing/ServicesOffered';
import AboutUs from 'components/marketing/AboutUs';
import BigHeader from 'components/baseElements/BigHeader';
import Footer from 'components/marketing/Footer/web';

export default function LandingContainer(): JSX.Element {
  // LOCAL (CONTAINER) STATE SETUP
  return (
    <>
      <BigHeader pageTitle="For All Your Wine Storage Needs" />

      <ServicesOffered />

      <AboutUs />

      <Footer />
    </>
  );
}
