import { SearchbarChangeEventDetail } from '@ionic/core/dist/types/components/searchbar/searchbar-interface';
import { IonSearchbar } from '@ionic/react';
import React, { createRef } from 'react';
import styled from 'styled-components';

interface SearchBarProperties {
  borderColor?: string;
  cancelButtonText?: string;
  disableAutoFocus?: boolean;
  debounce?: number;
  searchText?: string;
  showCancelButton?: 'never' | 'focus' | 'always';
  onIonCancel?: (event: CustomEvent<void>) => void;
  onIonChange?: (event: CustomEvent<SearchbarChangeEventDetail>) => void;
  onIonClear?: (event: CustomEvent<void>) => void;
  placeHolder?: string;
}

const IonSearchbarBase = styled(IonSearchbar)`
  --ion-color-base: rgba(0,0,0,0.05) !important;
  .searchbar-input-container {
    ${(props): string => props.borderColor ? `border: 1px solid var(--ion-color-${props.borderColor});` : ''} ${(props): string => props.borderColor ? 'border-radius: 10px' : ''}
  }
`;

export function SearchBar(props: SearchBarProperties): JSX.Element {
  const {
    borderColor,
    cancelButtonText,
    debounce,
    onIonCancel,
    onIonChange,
    onIonClear,
    searchText,
    showCancelButton = 'always',
    placeHolder,
  } = props;
  // Focus on the input element when rendered the first time
  const searchBarRef = createRef<HTMLIonSearchbarElement>();
  return (
    <IonSearchbarBase
      borderColor={borderColor}
      cancelButtonText={cancelButtonText}
      color="light"
      debounce={debounce ?? 1000}
      mode="ios"
      value={searchText}
      onIonCancel={onIonCancel}
      onIonChange={onIonChange}
      onIonClear={onIonClear}
      ref={searchBarRef}
      showCancelButton={showCancelButton}
      placeholder={placeHolder}
    />
  );
}
