import { GrapeButton, useAlert } from 'napa-react-core';
import { Flex } from 'components/baseElements/grid';
import UserDetailsForm from 'components/users/UserDetailsForm';
import useDeleteUser from 'customHooks/users/useDeleteUser';
import useUpdateUser from 'customHooks/users/useUpdateUser';
import useUserDetails from 'customHooks/users/useUserDetails';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { User } from 'types/users';
import { schema } from './validation';

export interface UserDetailsContainerProps {
  userId: string;
  successHandler?: (user: User) => any;
}

export default function UserDetailsContainer(props: UserDetailsContainerProps): JSX.Element {
  const { userId, successHandler } = props;
  const { data: userData, isLoading: fetchIsLoading } = useUserDetails(userId);
  const updateUser = useUpdateUser(userId);
  const deleteUser = useDeleteUser(userId);
  const intl = useIntl();
  const alert = useAlert();
  const saveUser = async (data: User): Promise<void> => {
    try {
      const response = await updateUser.mutateAsync(data);
      if (response && !response.validationErrors) {
        if (successHandler) {
          successHandler(data);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const removeUser = async (): Promise<void> => {
    try {
      const response = await deleteUser.mutateAsync();
      if (response && !response.validationErrors) {
        if (successHandler) {
          successHandler(response.data);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <>
      <UserDetailsForm
        user={userData}
        dataIsLoading={fetchIsLoading}
        validationSchema={schema}
        submitAction={saveUser}
      />
      <Flex>
        <GrapeButton
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          color="danger"
          fill="clear"
          onClick={(): void =>
            alert.showAlert({
              body: intl.formatMessage({ id: 'userDetailsContainer.deleteConfirmBody' }),
              header: intl.formatMessage({ id: 'userDetailsContainer.deleteConfirmHeader' }),
              buttons: [
                {
                  text: intl.formatMessage({
                    id: 'userDetailsContainer.deleteConfirmCancelButtonLabel',
                  }),
                  role: 'cancel',
                },
                {
                  text: intl.formatMessage({
                    id: 'userDetailsContainer.deleteConfirmOkButtonLabel',
                  }),
                  handler: removeUser,
                },
              ],
            })
          }
        >
          <FormattedMessage id="userDetailsContainer.deleteLabel" />
        </GrapeButton>
      </Flex>
    </>
  );
}
