import { yupResolver } from '@hookform/resolvers/yup';
import { IonInput, IonList } from '@ionic/react';
import { handleServerHookForm } from '@napa/react-core';
import { GrapeButton, Heading, Paragraph } from 'napa-react-core';
import HookInput from 'components/baseElements/formControls/HookInput';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface ForgotPasswordFormProperties {
  submitAction: (data: any) => any;
  serverValidation?: any;
  validationSchema: any;
}

export default function ForgotPasswordFormWeb(props: ForgotPasswordFormProperties): JSX.Element {
  const { submitAction, serverValidation, validationSchema } = props;

  const form = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  return (
    <form onSubmit={form.handleSubmit(submitAction)} noValidate>
      <Heading style={{ textAlign: 'center' }}>
        <FormattedMessage id="forgotPasswordForm.header" />
      </Heading>
      <Paragraph maxWidth={300} mx="auto" style={{ textAlign: 'center' }}>
        <FormattedMessage id="forgotPasswordForm.caption" />
      </Paragraph>
      <IonList lines="full">
        <HookInput form={form} isRequired labelText="forgotPasswordForm.emailLabel" name="email">
          <IonInput />
        </HookInput>
      </IonList>
      <GrapeButton expand="block" type="submit">
        <FormattedMessage id="forgotPasswordForm.resetButtonLabel" />
      </GrapeButton>
    </form>
  );
}
