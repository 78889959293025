/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
} from '@ionic/react';
import { Box, Flex } from 'components/baseElements/grid';
import FooterWeb from 'components/marketing/Footer/web';
import AuthorizationProcessContainer from 'containers/authorization/AuthorizationProcessContainer';
import { menu } from 'ionicons/icons';
import { TokenData } from '@napa/react-core';
import { Hideable } from '@napa/ui-react';
import { useAuthorization, PrivatePropertyContainer } from '@napa/react-core';
import {
  Ionic,
  useModal,
  UXComponent,
  Web,
} from 'napa-react-core';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { AppContent, AppTitle, AppToolbar } from './styles';

interface AppPageParams {
  isPrivate?: boolean;
  unauthorizedComponent?: JSX.Element;
  title?: string;
  hideBackButton?: boolean;
  children: any;
  noMobileHeaderAndFooter?: boolean;
  fullScreen?: boolean;
  hideFooter?: boolean;
}

export function AppPage(params: AppPageParams): JSX.Element {
  const { noMobileHeaderAndFooter = false } = params;
  const { isLoggedIn, removeToken, tokenData } = useAuthorization();
  const { showModal, hideModal } = useModal();
  const history = useHistory();
  const handleLoginSuccess = useCallback(
    (payload: TokenData): any => {
      hideModal();
      if (payload['meta/isAdmin'] === 'True') {
        history.push('/users');
      } else {
        history.push('/inventory');
      }
    },
    [history, hideModal],
  );

  const modalBody = useMemo(() => {
    return <AuthorizationProcessContainer successHandler={handleLoginSuccess} />;
  }, [handleLoginSuccess]);

  const handleLogout = (): void => {
    removeToken();
    // Push to landing page if not there
    if (history.location.pathname !== '/landing') {
      history.push('/landing');
    }
  };

  const [toggle, setToggle] = useState(false)

  return (
    <UXComponent>
      <Web>
        <IonPage>
          <IonHeader style={{ background: '#23282d' }}>
            <Flex
              width={1}
              alignItems="center"
              maxWidth={1100}
              mx="auto"
              flexDirection={['column', 'row']}
            >
              <Box flex={1} />
              <Box py={1} width={[1, 'auto']}>
                <Flex width={[1, 'auto']}>
                  <Box display={['block', 'none']}>
                    <IonButton color="light" fill="clear" onClick={() => setToggle(!toggle)}>
                      <IonIcon slot="icon-only" icon={menu} />
                    </IonButton>
                  </Box>
                  <Box flex={1} />
                  <Link style={{ display: 'flex' }} to="/landing">
                    <img
                      alt="logo"
                      src={`${process.env.PUBLIC_URL}/assets/images/AckerStorage-07.png`}
                      style={{ height: '45px' }}
                    />
                  </Link>
                  <Box display={['block', 'none']} flex={1} />
                  <Box display={['block', 'none']} opacity={0}>
                    <IonButton color="light" fill="clear">
                      <IonIcon slot="icon-only" icon={menu} />
                    </IonButton>
                  </Box>
                </Flex>
              </Box>
              <Flex
                flex={1}
                justifyContent="flex-end"
                flexDirection={['column', 'row']}
                maxHeight={[(toggle ? 'none' : 0), 'none' ]}
                opacity={[(toggle ? 1 : 0), 1]}
              >
                <Hideable hide={isLoggedIn}>
                  <IonButton
                    data-cy-login-button=""
                    fill="clear"
                    color="light"
                    onClick={(): void =>
                      showModal({
                        body: modalBody,
                        title: (
                          <div>
                            <FormattedMessage id="loginForm.loginButtonLabel" />
                          </div>
                        ),
                      })
                    }
                  >
                    <FormattedMessage id="componentExamplesContainer.signUpButtonLabel" />
                  </IonButton>
                </Hideable>
                <Hideable hide={!tokenData || tokenData['meta/isAdmin'] !== 'True'}>
                  <IonButton fill="clear" color="light" routerLink="/users">
                    <FormattedMessage id="users.browseUsersLabel" />
                  </IonButton>
                </Hideable>
                <Hideable hide={!tokenData || tokenData['meta/isAdmin'] === 'True'}>
                  <IonButton fill="clear" color="light" routerLink="/inventory">
                    <FormattedMessage id="users.browseInventoryLabel" />
                  </IonButton>
                </Hideable>
                <Hideable hide={!isLoggedIn}>
                  <>
                    <IonButton fill="clear" color="light" routerLink="/userProfile">
                      <FormattedMessage id="userProfile.profileLabel" />
                    </IonButton>
                    <IonButton fill="clear" color="light" onClick={() => handleLogout()}>
                      <FormattedMessage id="authorizationProcessContainer.logOffButtonLabel" />
                    </IonButton>
                  </>
                </Hideable>
              </Flex>
            </Flex>
          </IonHeader>
          <IonContent>
            {params.isPrivate ? (
              <PrivatePropertyContainer unauthorizedComponent={params.unauthorizedComponent}>
                {params.children}
              </PrivatePropertyContainer>
            ) : (
              <>{params.children}</>
            )}
            {params.hideFooter ? undefined : <FooterWeb />}
          </IonContent>
        </IonPage>
      </Web>
      <Ionic>
        <Hideable hide={noMobileHeaderAndFooter}>
          <IonHeader>
            <AppToolbar mode="ios">
              <IonButtons slot="start">
                <img
                  alt="logo"
                  src={`${process.env.PUBLIC_URL}/assets/images/AckerStorage-07.png`}
                  style={{ height: '30px', marginLeft: '8px' }}
                />
              </IonButtons>
              <AppTitle>
                <IonLabel>
                  <FormattedMessage id={params.title} />
                </IonLabel>
              </AppTitle>
            </AppToolbar>
          </IonHeader>
        </Hideable>
        <AppContent>
          {params.isPrivate ? (
            <PrivatePropertyContainer unauthorizedComponent={params.unauthorizedComponent}>
              {params.children}
            </PrivatePropertyContainer>
          ) : (
            <>{params.children}</>
          )}
        </AppContent>
      </Ionic>
    </UXComponent>
  );
}
