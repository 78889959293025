/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState } from 'react';

const store = createContext<{
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
}>({ state: {}, setState: () => null });
const { Provider } = store;

interface AppSettingsProviderProps {
  children: any;
}

const AppStateProvider = (props: AppSettingsProviderProps): any => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const [state, setState] = useState({});

  return <Provider value={{ state, setState }}>{props.children}</Provider>;
};

function useAppState(): { 
  wineSearch: string, 
  wineSort: {
    field: string;
    direction: 'asc' | 'desc';
  },
  setWineSearch: (search: string) => void,
  setWineSort: (sort: {
    field: string;
    direction: 'asc' | 'desc';
  }) => void
} 
{
  const appStateContext = useContext(store);

  return {
    wineSearch: appStateContext.state.wineSearch,
    wineSort: appStateContext.state.wineSort || { field: 'wineName', direction: 'asc' },
    setWineSearch: (search: string) => {
      appStateContext.setState( {...appStateContext.state, wineSearch: search })
    },
    setWineSort: (sort: {
      field: string;
      direction: 'asc' | 'desc';
    }) => {
      appStateContext.setState( {...appStateContext.state, wineSort: sort })
    },
  }
}

export { store as AppSettingsStore, AppStateProvider, useAppState }
