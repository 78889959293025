import { yupResolver } from '@hookform/resolvers/yup';
import { IonButton, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import { Box, Flex } from 'components/baseElements/grid';
import UserProfileAdditionalOptions from 'components/users/UserProfileAdditionalOptions';
import { localStorageKeys, useLocalStorage } from 'customHooks/filters';
import { SearchInventoryQueryParams } from 'customHooks/inventory/useInventory';
import { handleFormDataReset, handleServerHookForm } from '@napa/react-core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { User } from 'types/users';
import FooterIonic from '../../../marketing/Footer/ionic';

interface UserProfileFormProperties {
  submitAction?: (data: any) => any;
  dataIsLoading?: boolean;
  serverValidation?: any;
  validationSchema?: any;
  user?: User;
}

export default function UserProfileFormIonic(props: UserProfileFormProperties): JSX.Element {
  const { serverValidation, validationSchema, user } = props;
  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const history = useHistory();
  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  useEffect(() => {
    handleFormDataReset(user, form.reset);
  }, [user, form.reset]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterParams, setFilterParams] = useLocalStorage<SearchInventoryQueryParams>(
    localStorageKeys.browseInventoryItemsFilter,
    {
      searchText: '',
    },
  );

  const handleLogout = (): void => {
    // Ionic has a bug to show the tab even logged out https://github.com/ionic-team/ionic-framework/issues/20753
    // One workaround has been to use the location.href itself rather than history.push().
    // The logout page will do window.location.href = '/login'
    setFilterParams({ searchText: '' });
    if (window.location.pathname !== '/' && window.location.pathname !== '/logout') {
      history.push('/logout');
    }
  };

  return (
    <Box backgroundColor="white" py={3}>
      <IonList lines="none">
        <IonItem>
          <Flex flexDirection="column">
            <IonLabel id="email">
              <FormattedMessage id="userProfileForm.emailLabel" />
            </IonLabel>
            <IonText
              style={{
                fontWeight: '200',
                padding: '4px 0 4px 0',
                lineHeight: '24px',
                size: '16px',
              }}
            >
              {user?.email}
            </IonText>
          </Flex>
        </IonItem>
      </IonList>
      <Box mx={3}>
        <UserProfileAdditionalOptions />
      </Box>
      <Box mx={3}>
        <IonButton onClick={handleLogout}>
          <FormattedMessage
            data-cy-logout-button=""
            id="userProfileAdditionalOptions.logoutButtonLabel"
          />
        </IonButton>
      </Box>
      <FooterIonic />
    </Box>
  );
}
