import { IonRouterOutlet } from '@ionic/react';
import { useAuthorization } from '@napa/react-core';
import { Ionic, useToast, UXComponent, Web } from 'napa-react-core';
import LoginContainer from 'containers/authorization/LoginContainer';
import NotFoundContainer from 'containers/authorization/NotFoundContainer';
import BrowseInventoryContainer from 'containers/inventory/BrowseInventoryContainer';
import LandingContainer from 'containers/marketing/LandingContainer';
import { AppPage } from 'containers/page';
import BrowseUsersContainer from 'containers/users/BrowseUsersContainer';
import ForgotPasswordContainer from 'containers/users/ForgotPasswordContainer';
import ResetPasswordContainer from 'containers/users/ResetPasswordContainer';
import UserProfileContainer from 'containers/users/UserProfileContainer';
import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import LogoutContainer from '../../authorization/LogoutContainer';
import TabsContainer from '../TabsContainer';

export default function RoutesContainer(): JSX.Element {
  const history = useHistory();
  const toast = useToast();
  const intl = useIntl();
  const { isLoggedIn } = useAuthorization();

  return (
    <UXComponent>
      <Web>
        <Switch>
          <Route path="/landing" exact>
            <AppPage hideFooter>
              <LandingContainer />
            </AppPage>
          </Route>
          <Route path="/userProfile">
            <AppPage>
              <UserProfileContainer />
            </AppPage>
          </Route>
          <Route path="/inventory" exact>
            <AppPage>
              <BrowseInventoryContainer />
            </AppPage>
          </Route>
          <Route path="/resetPassword">
            <AppPage>
              <ResetPasswordContainer />
            </AppPage>
          </Route>
          <Route path="/users">
            <AppPage>
              <BrowseUsersContainer />
            </AppPage>
          </Route>
          <Route path="/404" exact>
            <AppPage title="404">
              <NotFoundContainer />
            </AppPage>
          </Route>
          <Route path="/" render={(): any => <Redirect to="/landing" />} exact={true} />
          <Redirect to="/404" />
        </Switch>
      </Web>
      <Ionic>
        <IonRouterOutlet id="main-content">
          <Route
            path="/"
            render={(): any =>
              isLoggedIn ? <Redirect to="/inventory" /> : <Redirect to="/login" />
            }
            exact
          />
          <Route
            path="/landing"
            render={(): any =>
              isLoggedIn ? <Redirect to="/inventory" /> : <Redirect to="/login" />
            }
            exact
          />
          <Route exact path="/login" component={(): any => <LoginContainer />} />
          <Route
            exact
            path="/logout"
            component={(): any => (
              <AppPage title="routesContainer.logoutTitle">
                <LogoutContainer />
              </AppPage>
            )}
          />
          <Route
            path="/forgotPassword"
            component={(): any => (
              <ForgotPasswordContainer
                successHandler={(): void => {
                  toast.showToast({
                    message: intl.formatMessage({ id: 'forgotPasswordForm.toasts.success' }),
                  });
                  history.push('/login');
                }}
              />
            )}
          />
          <Route path="/resetPassword" component={(): any => <ResetPasswordContainer />} />
          <Route path="/inventory" render={(): any => <TabsContainer />} />
          <Route path="/userProfile" render={(): any => <TabsContainer />} />
        </IonRouterOutlet>
      </Ionic>
    </UXComponent>
  );
}
