import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { GrapeButton, Ionic, useModal, UXComponent, Web } from 'napa-react-core';
import ChangePasswordContainer from 'containers/users/ChangePasswordContainer';
import { IonButton } from '@ionic/react';

export default function UserProfileAdditionalOptions(): JSX.Element {
  const { showModal, hideModal } = useModal();
  const handleSuccess = useCallback((): any => {
    hideModal();
  }, [hideModal]);

  const modalBody = useMemo(() => {
    return <ChangePasswordContainer successHandler={handleSuccess} />;
  }, [handleSuccess]);
  return (
    <UXComponent>
      <Web>
        <GrapeButton
          expand="block"
          onClick={(): void =>
            showModal({
              body: modalBody,
              title: <FormattedMessage id="changePasswordForm.title" />,
            })
          }
        >
          <FormattedMessage id="userProfileAdditionalOptions.changePasswordButtonLabel" />
        </GrapeButton>
      </Web>
      <Ionic>
        <IonButton
          onClick={(): void =>
            showModal({
              body: modalBody,
              title: <FormattedMessage id="changePasswordForm.title" />,
            })
          }
        >
          <FormattedMessage id="userProfileAdditionalOptions.changePasswordButtonLabel" />
        </IonButton>
      </Ionic>
    </UXComponent>
  );
}
