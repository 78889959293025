import { yupResolver } from '@hookform/resolvers/yup';
import { IonInput, IonList } from '@ionic/react';
import { handleServerHookForm } from '@napa/react-core';
import { GrapeButton } from 'napa-react-core';
import HookInput from 'components/baseElements/formControls/HookInput';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface LoginFormProperties {
  submitAction: (data: any) => any;
  serverValidation?: any;
  validationSchema?: any;
}

export default function LoginFormWeb(props: LoginFormProperties): JSX.Element {
  const { submitAction, validationSchema, serverValidation } = props;

  const form = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  return (
    <form onSubmit={form.handleSubmit(submitAction)} noValidate>
      <IonList lines="full">
        <HookInput form={form} isRequired labelText="loginForm.emailLabel" name="username">
          <IonInput name="username" />
        </HookInput>
        <HookInput form={form} isRequired labelText="loginForm.passwordLabel" name="password">
          <IonInput clearOnEdit={false} name="password" type="password" />
        </HookInput>
      </IonList>
      <GrapeButton data-cy-login-form-button="" expand="block" type="submit">
        <FormattedMessage id="loginForm.loginButtonLabel" />
      </GrapeButton>
    </form>
  );
}
