import { ellipsis, transient } from 'napa-react-core';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';

const Box = styled('div')`
  ${transient(background)}
  ${transient(border)}
  ${transient(color)}
  ${transient(flexbox)}
  ${transient(grid)}
  ${transient(layout)}
  ${transient(position)}
  ${transient(shadow)}
  ${transient(space)}
  ${transient(typography)}
  ${ellipsis}
`;

const Flex = styled(Box)``;

Flex.defaultProps = {
  $display: 'flex',
};

export { Box, Flex };
