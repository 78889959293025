import * as yup from 'yup';

export const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required('api.validation.invalidPassword')
    .min(8, 'api.validation.invalidPassword')
    .test('passwordsMustMatch', 'validation.unmatchedPasswordsErrorLabel', function (): boolean {
      return this.parent.newPassword === this.parent.passwordConfirm;
    }),
  passwordConfirm: yup
    .string()
    .required('api.validation.invalidPassword')
    .min(8, 'api.validation.invalidPassword')
    .test('passwordsMustMatch', 'validation.unmatchedPasswordsErrorLabel', function (): boolean {
      return this.parent.newPassword === this.parent.passwordConfirm;
    }),
});
