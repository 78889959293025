import { SearchbarChangeEventDetail } from '@ionic/core/dist/types/components/searchbar/searchbar-interface';
import { AuthorizationStore} from '@napa/react-core';
import { Ionic, UXComponent, Web } from 'napa-react-core';
import Header from 'components/baseElements/Header';
import { Loadable, Loaded, Unloaded } from 'components/baseElements/loadable';
import { SearchBar } from 'components/baseElements/SearchBar';
import InventoryList from 'components/inventory/InventoryList';
import useInventory  from 'customHooks/inventory/useInventory';
import { Box, Flex, Text } from '@napa/grape-ui-react';
import { useAppState } from 'providers/appState';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import RiseLoader from 'react-spinners/RiseLoader';
import styled from 'styled-components';

const WineBox = styled(Box)`
  width: 90%;
  flex: 1;
  margin-bottom: 10px;
`;

interface BrowseInventoryContainerProps {
  apcId?: string;
}

export default function BrowseInventoryContainer(
  props: BrowseInventoryContainerProps,
): JSX.Element {
  const { apcId } = props;
  const authData = useContext(AuthorizationStore);
  const inventoryApcId =
    apcId || (authData.state?.tokenData && authData.state.tokenData['meta/apcId']);
  const intl = useIntl();

  const { setWineSearch, wineSearch } = useAppState();
  const {
    data: inventoryItems,
    isLoading: inventoryIsLoading,
    error: apiError,
  } = useInventory({ apcId: inventoryApcId });

  return (
    <>
      <UXComponent>
        <Ionic>
          <Loadable isLoading={inventoryIsLoading}>
            <Unloaded>
              <Flex flexDirection="column">
                <Box
                  alignSelf="center"
                  alignItems="center"
                  justifyContent="center"
                  pt="150px"
                  pb="50px"
                >
                  <RiseLoader size={50} color={'rgb(128,23,71)'} loading={true} />
                </Box>
                <Box alignSelf="center" alignItems="center" justifyContent="center">
                  <Text fontWeight="bold" fontSize="28pt">
                    Loading Your Wine
                  </Text>
                </Box>
              </Flex>
            </Unloaded>
            <Loaded>
              <SearchBar
                cancelButtonText={intl.formatMessage({
                  id: 'browseInventoryContainer.searchBar.cancelText',
                })}
                debounce={1}
                disableAutoFocus
                onIonChange={(e: CustomEvent<SearchbarChangeEventDetail>): void => {
                  setWineSearch(e.detail.value || '');
                }}
                onIonClear={(): void => {
                  setWineSearch('');
                }}
                searchText={wineSearch}
                showCancelButton="never"
                placeHolder={intl.formatMessage({
                  id: 'browseInventoryContainer.searchBar.placeholderText',
                })}
              />
              <InventoryList
                inventoryItems={inventoryItems?.items}
                searchText={wineSearch}
                showError={apiError}
                totalItems={inventoryItems?.totalItems}
              />
            </Loaded>
          </Loadable>
        </Ionic>
        <Web>
          <Flex justifyContent="center" flexDirection="column" height="100%">
            <Box>
              <Header
                pageTitle={intl.formatMessage({
                  id: 'users.browseInventoryLabel',
                })}
              />
            </Box>
            <WineBox alignSelf="center">
              <Loadable isLoading={inventoryIsLoading}>
                <Unloaded>
                  <Flex flexDirection="column">
                    <Box
                      alignSelf="center"
                      alignItems="center"
                      justifyContent="center"
                      pt="150px"
                      pb="50px"
                    >
                      <RiseLoader size={50} color={'rgb(128,23,71)'} loading={true} />
                    </Box>
                    <Box alignSelf="center" alignItems="center" justifyContent="center">
                      <Text fontWeight="bold" fontSize="28pt">
                        Loading Your Wine
                      </Text>
                    </Box>
                  </Flex>
                </Unloaded>
                <Loaded>
                  <InventoryList
                    inventoryItems={inventoryItems?.items}
                    showError={apiError}
                    totalItems={inventoryItems?.totalItems}
                  />
                </Loaded>
              </Loadable>
            </WineBox>
          </Flex>
        </Web>
      </UXComponent>
    </>
  );
}
