import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { GrapeText } from 'napa-react-core';
import React from 'react';

interface AssistiveTextProps {
  children?: React.ReactNode;
  color?: string;
  ionColProps?: any;
  ionGridProps?: any;
  ionRowProps?: any;
}

export default function AssistiveText(props: AssistiveTextProps): JSX.Element {
  const {
    children,
    color = 'medium',
    ionColProps = {
      className: 'ion-no-padding',
    },
    ionGridProps = {
      className: 'assistive-text ion-padding-bottom ion-padding-horizontal',
      style: {
        backgroundColor: 'var(--background)',
      },
    },
    ionRowProps = {
      className: 'ion-no-padding',
    },
  } = props;

  return (
    <IonGrid {...ionGridProps} {...props}>
      <IonRow {...ionRowProps}>
        <IonCol {...ionColProps}>
          <GrapeText color={color} $display="block" $fontSize="75%" $fontWeight={400}>
            {children}
          </GrapeText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}
