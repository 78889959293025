import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonList,
  IonToolbar,
} from '@ionic/react';
import { handleServerHookForm } from '@napa/react-core';
import { IonFormControl } from 'napa-react-core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppFooter, StyledIonPage } from '../../../../containers/page/styles';
import FooterIonic from '../../../marketing/Footer/ionic';
import { ToolbarLogo } from '../../../marketing/ToolbarLogo';

interface ResetPasswordFormIonicProperties {
  submitAction: (data: any) => any;
  changePasswordIsSaving?: boolean;
  serverValidation?: any;
  validationSchema?: any;
}

export default function ResetPasswordFormIonic(
  props: ResetPasswordFormIonicProperties,
): JSX.Element {
  const { submitAction, serverValidation, validationSchema } = props;
  const intl = useIntl();
  const { control, errors, handleSubmit, setError } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      passwordConfirm: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleServerHookForm(serverValidation, setError);
  }, [serverValidation, setError]);

  return (
    <StyledIonPage>
      <IonHeader mode="md" translucent>
        <IonToolbar style={{ '--background': 'transparent' }}>
          <ToolbarLogo />
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ '--background': 'transparent' }}>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              <FormattedMessage id="resetPasswordForm.title" />
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <form noValidate onSubmit={handleSubmit(submitAction)}>
              <IonList lines="full">
                <IonFormControl
                  control={control}
                  errorMessage={
                    errors.newPassword?.message &&
                    intl.formatMessage({ id: errors.newPassword?.message })
                  }
                  helperText={intl.formatMessage({
                    id: 'resetPasswordForm.passwordAssistiveText',
                  })}
                  isInvalid={!!errors.newPassword}
                  id="newPassword"
                  isRequired
                  labelText={intl.formatMessage({
                    id: 'resetPasswordForm.newPasswordLabel',
                  })}
                >
                  <IonInput type="password" />
                </IonFormControl>
                <IonFormControl
                  control={control}
                  errorMessage={
                    errors.passwordConfirm?.message &&
                    intl.formatMessage({ id: errors.passwordConfirm?.message })
                  }
                  isInvalid={!!errors.passwordConfirm}
                  id="passwordConfirm"
                  isRequired
                  labelText={intl.formatMessage({
                    id: 'resetPasswordForm.passwordConfirmLabel',
                  })}
                >
                  <IonInput type="password" />
                </IonFormControl>
              </IonList>
              <IonButton expand="block" type="submit">
                <FormattedMessage id="resetPasswordForm.saveButtonLabel" />
              </IonButton>
              <IonButton expand="block" fill="clear" routerLink="/login" type="button">
                <FormattedMessage id="resetPasswordForm.backToLogin" />
              </IonButton>
            </form>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <AppFooter mode="md">
        <FooterIonic />
      </AppFooter>
    </StyledIonPage>
  );
}
