import ChangePasswordFormIonic from 'components/users/ChangePasswordForm/ionic';
import { AuthorizationStore } from '@napa/react-core';
import { Ionic, UXComponent, Web } from 'napa-react-core';
import ChangePasswordFormWeb from 'components/users/ChangePasswordForm/web';
import useChangePassword from 'customHooks/users/useChangePassword';
import React, { useContext } from 'react';
import { ChangePasswordRequest } from 'types/users';
import { Box } from '../../../components/baseElements/grid';
import { schema } from './validation';

export interface ChangePasswordContainerProps {
  successHandler: () => any;
}

export default function ChangePasswordContainer(props: ChangePasswordContainerProps): JSX.Element {
  const { successHandler } = props;
  const changePassword = useChangePassword({
    successHandler: () => {
      successHandler();
    },
  });
  const authData = useContext(AuthorizationStore);

  const handleChangePasswordSubmit = async (data: ChangePasswordRequest): Promise<void> => {
    data.id = authData.state.tokenData && authData.state.tokenData['userData/userId'];
    try {
      await changePassword.mutateAsync(data);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <UXComponent>
      <Web>
        <ChangePasswordFormWeb
          changePasswordIsSaving={changePassword.isLoading}
          serverValidation={changePassword?.error?.validationErrors}
          showExistingPassword
          submitAction={handleChangePasswordSubmit}
          validationSchema={schema}
        />
      </Web>
      <Ionic>
        <Box mx="auto" maxWidth={600} width={1}>
          <ChangePasswordFormIonic
            changePasswordIsSaving={changePassword.isLoading}
            serverValidation={changePassword?.error?.validationErrors}
            showExistingPassword
            submitAction={handleChangePasswordSubmit}
            validationSchema={schema}
          />
        </Box>
      </Ionic>
    </UXComponent>
  );
}
