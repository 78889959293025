import { PaginationControls } from 'napa-react-core';
import { Box, Button, Flex } from '@napa/grape-ui-react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDoubleLeft, faChevronDoubleRight, faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

interface PaginatorProps {
  currentPage: number;
  maxPages: number;
  controls: PaginationControls;
}

export default function Paginator(props: PaginatorProps): JSX.Element {
  const { controls, currentPage, maxPages } = props;
  return (
    <Flex flexDirection="row" flexWrap="wrap" justifyContent="flex-end">
      <Box alignItems="left" alignSelf="center">
        Page {currentPage} of {maxPages}
      </Box>
      <Box alignItems="right">
        <Button m={1} disabled={currentPage === 1} onClick={(): void => controls.goTo(1)}>
          <FontAwesomeIcon icon={faChevronDoubleLeft} />
        </Button>
        <Button disabled={currentPage === 1} onClick={(): void => controls.previous()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Button disabled={currentPage === maxPages} onClick={(): void => controls.next()}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
        <Button disabled={currentPage === maxPages} onClick={(): void => controls.goTo(maxPages)}>
          <FontAwesomeIcon icon={faChevronDoubleRight} />
        </Button>
      </Box>
    </Flex>
  );
}
