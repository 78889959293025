import { CustomHookProps, useBasicMutation, TokenRequest } from '@napa/react-core';

export default function useLogin(props?: CustomHookProps<TokenRequest>): any {
  return useBasicMutation<TokenRequest>({
    apiRoute: 'tokens',
    httpMethod: 'POST',
    isFormData: false,
    params: props,
  });
}
