import { faUserCircle, faWineBottle } from '@fortawesome/pro-light-svg-icons';
import { setupConfig } from '@ionic/core';
import { IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { TabLabel } from 'components/baseElements/TabLabel';
import BrowseInventoryContainer from 'containers/inventory/BrowseInventoryContainer';
import { AppPage as AppPageIonic } from 'containers/page';
import UserProfileContainer from 'containers/users/UserProfileContainer';
import React, {useEffect} from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import LogoutContainer from '../../authorization/LogoutContainer';
import {useAuthorization} from '@napa/react-core';
import PrivateRoute from '../../../components/baseElements/privateRoute';

const StyledIonTabs = styled(IonTabs)`
  background-color: white;
`;

setupConfig({
  swipeBackEnabled: false,
});

const InventoryTab = (): JSX.Element => (
  <AppPageIonic title="routesContainer.browseInventoryTitle">
    <BrowseInventoryContainer />
  </AppPageIonic>
);

const LogoutTab = (): JSX.Element => (
  <AppPageIonic title="routesContainer.logoutTitle">
    <LogoutContainer />
  </AppPageIonic>
);

const UserProfileTab = (): JSX.Element => (
  <AppPageIonic title="routesContainer.myProfileTitle">
    <UserProfileContainer />
  </AppPageIonic>
);

export default function TabsContainer(): JSX.Element {
  const { isLoggedIn } = useAuthorization();
  const history = useHistory();

  useEffect(() => {
    // Logout user if not logged in
    if (!isLoggedIn) {
      history.push('/landing');
    }
  }, [history, isLoggedIn]);

  if (!isLoggedIn) {
    return <></>;
  }

  return (
    <StyledIonTabs>
      <IonRouterOutlet>
        <PrivateRoute
          exact
          path="/:tab(inventory)"
          component={InventoryTab}
        />
        <PrivateRoute
          exact
          path="/:tab(userProfile)"
          component={UserProfileTab}
        />
        <Route
          exact
          path="/:tab(logout)"
          component={LogoutTab}
        />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="inventory" href="/inventory">
          <TabLabel icon={faWineBottle}>
            <FormattedMessage id="routesContainer.browseInventoryTitle" />
          </TabLabel>
        </IonTabButton>
        <IonTabButton tab="userProfile" href="/userProfile">
          <TabLabel icon={faUserCircle}>
            <FormattedMessage id="routesContainer.userProfilePageTitle" />
          </TabLabel>
        </IonTabButton>
      </IonTabBar>
    </StyledIonTabs>
  );
}
