import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
} from '@ionic/react';
import { Hideable } from '@napa/ui-react';
import { useAppSettings } from '@napa/react-core';
import {
  Ionic,
  Paragraph,
  useDataList,
  useModal,
  UXComponent,
  Web,
  stringContains
} from 'napa-react-core';
import SortIndicator from 'components/baseElements/DataList/SortIndicator';
import Paginator from 'components/baseElements/Paginator';
import { Caption } from 'components/baseElements/typography/Caption';
import { Box, Flex, Header } from '@napa/grape-ui-react';
import { useAppState } from 'providers/appState';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { InventoryItem } from 'types/inventory';
import {
  getInventoryQuantityText,
  getInventoryEstimateText,
  getInventoryFullName,
} from 'utils/inventory';
import InventoryDetail from '../InventoryDetail';
import InventoryItemInline from '../InventoryItemInline';
import InventorySortFilter from '../InventorySortFilter';
import {
  FilterBox,
  FilterTextInput,
  GridBody,
  GridHeader,
  HeaderCol,
  HeaderRow,
  InventoryRow,
  InventoryTable,
  SortBox,
  TableOuterBox,
} from './styles';

interface InventoryListProperties {
  inventoryItems?: Array<InventoryItem>;
  searchText?: string;
  totalItems?: number;
  showError: boolean;
}

export default function InventoryList(props: InventoryListProperties): JSX.Element {
  const { inventoryItems, searchText, showError } = props;
  const { wineSort } = useAppState();
  const { showModal } = useModal();
  const { isIonic } = useAppSettings();
  const itemRender = (item: InventoryItem, key: any) => (
    <UXComponent key={key}>
      <Ionic>
        <IonItem
          lines="full"
          onClick={() =>
            showModal({
              body: <InventoryDetail inventoryItem={item} />,
            })
          }
        >
          <IonLabel>
            <Caption mb="4px !important">
              {`${getInventoryQuantityText(item)} ${getInventoryEstimateText(item, true)}`}
            </Caption>
            <Header.h4
              style={{ color: '#a21c3a', fontSize: '16px', lineHeight: '1.2', margin: '0' }}
              className="ion-text-wrap"
            >
              {getInventoryFullName(item)}
            </Header.h4>
            {item?.designation && (
              <Paragraph color="#3B2827 !important">{item.designation}</Paragraph>
            )}
          </IonLabel>
        </IonItem>
      </Ionic>
      <Web>
        <InventoryRow key={key}>
          <InventoryItemInline inventoryItem={item} />
        </InventoryRow>
      </Web>
    </UXComponent>
  );
  const {
    applyFilter,
    applySort,
    currentPage,
    filterCriteria,
    filteredItemCount,
    maxPages,
    paginationControls,
    setItems,
    renderedItems,
    sortCriteria,
    totalItemCount,
  } = useDataList({
    itemRender: itemRender,
    defaultSort: wineSort,
    pageSize: isIonic? 20 : 500,
    isInfinite: isIonic,
    customFilters: [{
      name: 'search',
      filter: (item: InventoryItem, criteria: string): boolean => {
        if (!criteria || criteria.length === 0) {
          return true;
        }
        // Search only on visible fields
        if (stringContains(item.designation, criteria)) {
          return true;
        }
        if (stringContains(item.quantity?.toString() || '', criteria)) {
          return true;
        }
        if (stringContains(item.producer, criteria)) {
          return true;
        }
        if (stringContains(item.vintage, criteria)) {
          return true;
        }
        return stringContains(item.wineName, criteria);
      }
    }]

  });
  const scrollableBox = React.createRef();

  useEffect(() => {
    applyFilter('search', searchText)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    setItems(inventoryItems || []);
  }, [setItems, inventoryItems]);

  return (
    <>
      {inventoryItems && inventoryItems.length ? (
        <UXComponent>
          <Ionic>
            <InventorySortFilter applySort={applySort} sortCriteria={sortCriteria} />
            <IonList>
              {renderedItems}
              <IonInfiniteScroll
                threshold="100px"
                disabled={false}
                onIonInfinite={(e: CustomEvent<void>) => {
                  paginationControls.next();
                  (e.target as HTMLIonInfiniteScrollElement)
                    .complete()
                    // eslint-disable-next-line no-console
                    .catch(console.error);
                }}
              >
                <IonInfiniteScrollContent loadingText="Loading more wines..." />
              </IonInfiniteScroll>
            </IonList>
          </Ionic>
          <Web>
            <Flex flexDirection="column" height="100%" justifyContent="flex-start">
              <Box mb="5px">
                <Flex flexDirection={['column', 'row']} justifyContent={[null, 'space-between']}>
                  <Box alignSelf={[null, 'center']}>
                    <Flex flexDirection="column">
                      <Box>Total items: {totalItemCount}</Box>
                      <Hideable hide={totalItemCount === filteredItemCount}>
                        <Box>Items Matching Filter: {filteredItemCount}</Box>
                      </Hideable>
                    </Flex>
                  </Box>
                  <Paginator
                    currentPage={currentPage}
                    maxPages={maxPages}
                    controls={paginationControls}
                  />
                </Flex>
              </Box>
              <TableOuterBox ref={scrollableBox}>
                <InventoryTable>
                  <GridHeader>
                    <HeaderRow>
                      <HeaderCol width="85px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('quantity')}>
                            Quantity
                            <SortIndicator
                              fieldName="quantity"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.quantity}
                              onChange={(e: any): void => applyFilter('quantity', e.target.value)}
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="110px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('bottleName')}>
                            Bottle&nbsp;Size&nbsp;
                            <SortIndicator
                              fieldName="bottleName"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.bottleName}
                              onChange={(e: any): void => applyFilter('bottleName', e.target.value)}
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="85px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('vintage')}>
                            Vintage
                            <SortIndicator
                              fieldName="vintage"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.vintage}
                              onChange={(e: any): void => applyFilter('vintage', e.target.value)}
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="300px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('wineName')}>
                            Wine Name
                            <SortIndicator
                              fieldName="wineName"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.wineName}
                              onChange={(e: any): void => applyFilter('wineName', e.target.value)}
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="300px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('designation')}>
                            Designation
                            <SortIndicator
                              fieldName="designation"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.designation}
                              onChange={(e: any): void =>
                                applyFilter('designation', e.target.value)
                              }
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="200px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('producer')}>
                            Producer
                            <SortIndicator
                              fieldName="producer"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.producer}
                              onChange={(e: any): void => applyFilter('producer', e.target.value)}
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="200px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('condition')}>
                            Condition
                            <SortIndicator
                              fieldName="condition"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.condition}
                              onChange={(e: any): void => applyFilter('condition', e.target.value)}
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="85px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('boxCode')}>
                            Box
                            <SortIndicator
                              fieldName="boxCode"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.boxCode}
                              onChange={(e: any): void => applyFilter('boxCode', e.target.value)}
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="200px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('supplier')}>
                            Source
                            <SortIndicator
                              fieldName="supplier"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.supplier}
                              onChange={(e: any): void => applyFilter('supplier', e.target.value)}
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="110px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('appellation')}>
                            Appellation
                            <SortIndicator
                              fieldName="appellation"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.appelation}
                              onChange={(e: any): void =>
                                applyFilter('appellation', e.target.value)
                              }
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="150px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('regionDescription')}>
                            Region
                            <SortIndicator
                              fieldName="regionDescription"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.regionDescription}
                              onChange={(e: any): void =>
                                applyFilter('regionDescription', e.target.value)
                              }
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="95px">
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('locAbbr')}>
                            Location
                            <SortIndicator
                              fieldName="locAbbr"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                          <FilterBox>
                            <FilterTextInput
                              placeholder="Filter..."
                              isActive={filterCriteria.locAbbr}
                              onChange={(e: any): void => applyFilter('locAbbr', e.target.value)}
                            />
                          </FilterBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="140px" style={{ verticalAlign: 'top' }}>
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('lowEstimate')}>
                            Estimate
                            <SortIndicator
                              fieldName="lowEstimate"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                        </Flex>
                      </HeaderCol>
                      <HeaderCol width="185px" style={{ verticalAlign: 'top' }}>
                        <Flex flexDirection="column">
                          <SortBox onClick={(): void => applySort('whCreateDt')}>
                            Latest Addition
                            <SortIndicator
                              fieldName="whCreateDt"
                              currentSortName={sortCriteria.field}
                              currentSortDirection={sortCriteria.direction}
                            />
                          </SortBox>
                        </Flex>
                      </HeaderCol>
                    </HeaderRow>
                  </GridHeader>
                  <GridBody>{renderedItems}</GridBody>
                </InventoryTable>
              </TableOuterBox>
            </Flex>
          </Web>
        </UXComponent>
      ) : showError ? (
        <Box style={{ fontWeight: 200, fontSize: '24px', textAlign: 'center' }} px={3}>
          An unexpected error has occurred.
        </Box>
      ) : (
        <Box style={{ fontWeight: 200, fontSize: '24px', textAlign: 'center' }} px={3}>
          <FormattedMessage id="browseInventoryContainer.text.emptyCase" />
        </Box>
      )}
    </>
  );
}
