import { CustomHookProps, useBasicMutation } from '@napa/react-core';
import { User } from 'types/users';

export default function useInsertUser(props?: CustomHookProps<User>): any {
  return useBasicMutation<User>({
    apiRoute: 'users',
    httpMethod: 'POST',
    isFormData: false,
    params: props,
  });
}
