import React from 'react';
import styled from 'styled-components';
import {
  Flex,
  Header,
  Image,
  Link,
  Text,
} from '@napa/grape-ui-react';
import { UXComponent, Web } from 'napa-react-core';
import packageJson from '../../../../../package.json';

const FooterFlex = styled(Flex)`
  color: #9ba1a7;
`;

const TermsLink = styled(Link)``;

TermsLink.defaultProps = {
  color: '#bbb',
  hoverColor: '#fff',
}

const { version, versionDate } = packageJson;
const FooterWeb: React.FC = () => {
  return (
    <UXComponent>
      <Web>
        <FooterFlex
          alignItems="center"
          as="footer"
          flexDirection="column"
          py={32}
          style={{ background: '#23282d' }}
        >
          <Flex
            flexDirection={['column', 'row']}
            justifyContent="space-evenly"
            width={1}
          >
            <Flex alignItems={['center', 'flex-start']} flexDirection="column">
              <Header.h6 color="white">
                Download the Acker Storage App
              </Header.h6>
              <Flex alignItems="center" flexDirection={['column', 'row']} width={[130, 'auto']}>
                <Link
                  href="https://apps.apple.com/us/app/acker-storage/id1556750017"
                  mr={1}
                  target="_blank"
                >
                  <Image
                    alt="Download on the iOS App Store"
                    maxHeight={45}
                    src={`${process.env.PUBLIC_URL}/assets/images/Apple.jpeg`}
                    width="auto"
                  />
                </Link>
                <Link
                  href="https://play.google.com/store/apps/details?id=com.acker.storage"
                  ml={1}
                  target="_blank"
                >
                  <Image
                    alt="Download on the Google Play Store"
                    maxHeight={45}
                    src={`${process.env.PUBLIC_URL}/assets/images/GooglePlay.jpeg`}
                    width="auto"
                  />
                </Link>
              </Flex>
            </Flex>
            <Flex alignItems={['center', 'flex-start']} flexDirection="column" mt={[3, 0]} style={{ textAlign: 'center' }}>
              <Header.h6 color="white">Policies</Header.h6>
              <TermsLink href="/static-content/TermsOfUse.html">Terms of Use</TermsLink>
              <TermsLink href="/static-content/PrivacyPolicyUS.html">Privacy Policy US</TermsLink>
              <TermsLink href="/static-content/PrivacyPolicyNONUS.html">Privacy Policy Non-US</TermsLink>
              <TermsLink href="/static-content/refunds.html">Refund Policy</TermsLink>
            </Flex>
            <Flex alignItems={['center', 'flex-start']} flexDirection="column" mt={[3, 0]} style={{ textAlign: 'center' }}>
              <Header.h6 color="white">Contact</Header.h6>
              <TermsLink emailHref={{ to: 'info@ackerstorage.com' }}>info@ackerstorage.com</TermsLink>
              <span>P: <TermsLink href="tel:3023660801">302.366.0801</TermsLink></span>
              <span>F: <TermsLink href="fax:3023660805">302.366.0805</TermsLink></span>
            </Flex>
          </Flex>
          <Flex flexDirection={['column', 'row']} mt={4}>
            <Text mx={1} style={{ textAlign: 'center' }}>
              Copyright 2021 — Acker Storage. All rights reserved.
            </Text>
            <Text mx={1} style={{ textAlign: 'center' }}>
              v{version} on {versionDate}
            </Text>
          </Flex>
        </FooterFlex>
      </Web>
    </UXComponent>
  );
};

export default FooterWeb;
