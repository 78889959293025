import { Hideable } from '@napa/ui-react';
import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import styled from 'styled-components';
import { InventoryItem } from 'types/inventory';

const WineAttr = styled('td')`
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 2px solid rgba(128, 23, 71, 0.15);
  ${(props): any => props.centerContent && 'text-align: center'}
  ${(props): any => props.rightAlignContent && 'text-align: right'}
`;

interface InventoryItemInlineProperties {
  inventoryItem: InventoryItem;
}

export default function InventoryItemInline(props: InventoryItemInlineProperties): JSX.Element {
  const { inventoryItem } = props;
  return (
    <>
      <WineAttr centerContent>{inventoryItem.quantity}</WineAttr>
      <WineAttr>{inventoryItem.bottleName}</WineAttr>
      <WineAttr>{inventoryItem.vintage}</WineAttr>
      <WineAttr>{inventoryItem.wineName}</WineAttr>
      <WineAttr>{inventoryItem.designation}</WineAttr>
      <WineAttr>{inventoryItem.producer}</WineAttr>
      <WineAttr>{inventoryItem.condition}</WineAttr>
      <WineAttr>{inventoryItem.boxCode}</WineAttr>
      <WineAttr>{inventoryItem.supplier} </WineAttr>
      <WineAttr>{inventoryItem.appellation}</WineAttr>
      <WineAttr>{inventoryItem.regionDescription}</WineAttr>
      <WineAttr>{inventoryItem.locAbbr}</WineAttr>
      <WineAttr>
        <Hideable hide={!inventoryItem.lowEstimate && !inventoryItem.highEstimate}>
          <>
            ${inventoryItem.lowEstimate && inventoryItem.lowEstimate.toLocaleString()} - $
            {inventoryItem.highEstimate && inventoryItem.highEstimate.toLocaleString()}
          </>
        </Hideable>
      </WineAttr>
      <WineAttr>
        <FormattedDate value={inventoryItem.whCreateDt} />
        &nbsp;
        <FormattedTime value={inventoryItem.whCreateDt} />
      </WineAttr>
    </>
  );
}
