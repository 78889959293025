import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonList,
  IonToolbar,
} from '@ionic/react';
import { Hideable } from '@napa/ui-react';
import { handleServerHookForm } from '@napa/react-core';
import { IonFormControl } from 'napa-react-core';
import FooterIonic from 'components/marketing/Footer/ionic';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppFooter, StyledIonPage } from 'containers/page/styles';
import { ToolbarLogo } from '../../../marketing/ToolbarLogo';

interface LoginFormIonicProperties {
  submitAction: (data: any) => void;
  serverValidation?: any;
  validationSchema?: any;
}

export default function LoginFormIonic(props: LoginFormIonicProperties): JSX.Element {
  const { submitAction, validationSchema, serverValidation } = props;

  const { control, errors, handleSubmit, setError } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const { formatMessage } = useIntl();
  const [hideFooter, setHideFooter] = useState<boolean>(false);

  useEffect(() => {
    handleServerHookForm(serverValidation, setError);
  }, [serverValidation, setError]);
  return (
    <StyledIonPage>
      <IonHeader mode="md" translucent>
        <IonToolbar style={{ '--background': 'transparent' }}>
          <ToolbarLogo />
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ '--background': 'transparent' }}>
        <IonCard>
          <IonCardContent style={{ paddingBottom: 0, paddingTop: 0 }}>
            <form
              noValidate
              onBlur={(): void => setHideFooter(false)}
              onFocus={(): void => setHideFooter(true)}
              onSubmit={handleSubmit(submitAction)}
            >
              <IonList lines="full" style={{ paddingBottom: 0 }}>
                <IonFormControl
                  control={control}
                  errorMessage={
                    errors.username?.message && formatMessage({ id: errors.username?.message })
                  }
                  isInvalid={!!errors.username}
                  id="username"
                  isRequired
                  labelText={formatMessage({ id: 'loginForm.emailLabel' })}
                >
                  <IonInput type="text" />
                </IonFormControl>
                <IonFormControl
                  control={control}
                  errorMessage={
                    errors.password?.message && formatMessage({ id: errors.password?.message })
                  }
                  isInvalid={!!errors.password}
                  id="password"
                  isRequired
                  labelText={formatMessage({
                    id: 'loginForm.passwordLabel',
                  })}
                >
                  <IonInput clearOnEdit={false} type="password" />
                </IonFormControl>
                <IonButton data-cy-login-button="" expand="block" type="submit">
                  <FormattedMessage id="loginForm.loginButtonLabel" />
                </IonButton>
                <IonButton
                  data-cy-forgot-password-button=""
                  expand="block"
                  fill="clear"
                  routerLink="/forgotPassword"
                  type="button"
                >
                  <FormattedMessage id="loginForm.forgotPassword" />
                </IonButton>
              </IonList>
            </form>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <Hideable hide={hideFooter}>
        <AppFooter mode="md">
          <FooterIonic />
        </AppFooter>
      </Hideable>
    </StyledIonPage>
  );
}
