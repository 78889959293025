import React from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps, system } from 'styled-system';

export const StyledTableRow = styled('tr')`
  ${layout}
  ${system({ cursor: true })}
  ${props => props.onClick && css`cursor: pointer;`}
`;

interface StyledTableRowProps extends React.HTMLAttributes<HTMLFormElement>, LayoutProps {
  children?: React.ReactNode;
}

export function TableRow(props: StyledTableRowProps): JSX.Element {
  const {
    children,
    ...otherProps
  } = props;

  return (
    <StyledTableRow
      {...otherProps}
    >
      {children}
    </StyledTableRow>
  );
}
