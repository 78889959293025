import { yupResolver } from '@hookform/resolvers/yup';
import { IonInput, IonList } from '@ionic/react';
import { handleServerHookForm } from '@napa/react-core';
import { GrapeButton, IonFormControl } from 'napa-react-core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box } from '../../../baseElements/grid';

interface ChangePasswordFormIonicProperties {
  submitAction: (data: any) => any;
  changePasswordIsSaving?: boolean;
  serverValidation?: any;
  showExistingPassword?: boolean;
  validationSchema?: any;
}

export default function ChangePasswordFormIonic(
  props: ChangePasswordFormIonicProperties,
): JSX.Element {
  const { submitAction, serverValidation, showExistingPassword = false, validationSchema } = props;
  const intl = useIntl();
  const { control, errors, handleSubmit, register, setError } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      passwordConfirm: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleServerHookForm(serverValidation, setError);
  }, [serverValidation, setError]);

  return (
    <form id="changePasswordForm" onSubmit={handleSubmit(submitAction)} noValidate>
      <IonList lines="full">
        {showExistingPassword ? (
          <IonFormControl
            control={control}
            errorMessage={
              errors.currentPassword?.message &&
              intl.formatMessage({ id: errors.currentPassword?.message })
            }
            isInvalid={!!errors.currentPassword}
            id="currentPassword"
            isRequired
            labelText={intl.formatMessage({
              id: 'changePasswordForm.currentPasswordLabel',
            })}
          >
            <IonInput name="currentPassword" ref={register} type="password" />
          </IonFormControl>
        ) : undefined}
        <IonFormControl
          control={control}
          errorMessage={
            errors.newPassword?.message && intl.formatMessage({ id: errors.newPassword?.message })
          }
          helperText={intl.formatMessage({
            id: 'changePasswordForm.passwordAssistiveText',
          })}
          isInvalid={!!errors.newPassword}
          id="newPassword"
          isRequired
          labelText={intl.formatMessage({
            id: 'changePasswordForm.newPasswordLabel',
          })}
        >
          <IonInput type="password" />
        </IonFormControl>
        <IonFormControl
          control={control}
          errorMessage={
            errors.passwordConfirm?.message &&
            intl.formatMessage({ id: errors.passwordConfirm?.message })
          }
          isInvalid={!!errors.passwordConfirm}
          id="passwordConfirm"
          isRequired
          labelText={intl.formatMessage({
            id: 'changePasswordForm.passwordConfirmLabel',
          })}
        >
          <IonInput type="password" />
        </IonFormControl>
      </IonList>
      <Box px={3}>
        <GrapeButton expand="block" type="submit">
          <FormattedMessage id="changePasswordForm.saveButtonLabel" />
        </GrapeButton>
      </Box>
    </form>
  );
}
