import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonList,
  IonToolbar,
} from '@ionic/react';
import { Hideable } from '@napa/ui-react';
import { handleServerHookForm } from '@napa/react-core';
import { IonFormControl } from 'napa-react-core';
import { Paragraph } from 'components/baseElements/typography';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppFooter, StyledIonPage } from '../../../../containers/page/styles';
import FooterIonic from '../../../marketing/Footer/ionic';
import { ToolbarLogo } from '../../../marketing/ToolbarLogo';

interface ForgotPasswordFormProperties {
  submitAction: (data: any) => any;
  serverValidation?: any;
  validationSchema: any;
}

export default function ForgotPasswordFormIonic(props: ForgotPasswordFormProperties): JSX.Element {
  const { submitAction, serverValidation, validationSchema } = props;
  const intl = useIntl();
  const { control, errors, handleSubmit, setError } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const [hideFooter, setHideFooter] = useState<boolean>(false);

  useEffect(() => {
    handleServerHookForm(serverValidation, setError);
  }, [serverValidation, setError]);

  return (
    <StyledIonPage>
      <IonHeader mode="md" translucent>
        <IonToolbar style={{ '--background': 'transparent' }}>
          <ToolbarLogo />
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ '--background': 'transparent' }}>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              <FormattedMessage id="forgotPasswordForm.title" />
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <form
              noValidate
              onBlur={(): void => setHideFooter(false)}
              onFocus={(): void => setHideFooter(true)}
              onSubmit={handleSubmit(submitAction)}
            >
              <Paragraph maxWidth={300} mx="auto" style={{ textAlign: 'left' }}>
                <FormattedMessage id="forgotPasswordForm.caption" />
              </Paragraph>
              <IonList lines="full">
                <IonFormControl
                  control={control}
                  errorMessage={
                    errors.email?.message && intl.formatMessage({ id: errors.email?.message })
                  }
                  isInvalid={!!errors.email}
                  id="email"
                  isRequired
                  labelText={intl.formatMessage({ id: 'forgotPasswordForm.emailLabelIonic' })}
                >
                  <IonInput type="text" />
                </IonFormControl>
              </IonList>
              <IonButton expand="block" type="submit">
                <FormattedMessage id="forgotPasswordForm.resetButtonLabelIonic" />
              </IonButton>
              <IonButton expand="block" fill="clear" routerLink="/login" type="button">
                <FormattedMessage id="forgotPasswordForm.rememberYourPassword" />
              </IonButton>
            </form>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <Hideable hide={hideFooter}>
        <AppFooter mode="md">
          <FooterIonic />
        </AppFooter>
      </Hideable>
    </StyledIonPage>
  );
}
