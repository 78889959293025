import { useAuthorization } from '@napa/react-core';
import { Ionic, UXComponent, Web } from 'napa-react-core';
import {
  TokenData,
  TokenRequest,
  TokenResponse,
} from 'napa-react-core/dist/lib/providers/authorization/types';
import LoginFormIonic from 'components/authorization/LoginForm/ionic';
import LoginFormWeb from 'components/authorization/LoginForm/web';
import useLogin from 'customHooks/authorization/useLogin';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { schema } from './validation';

interface LoginContainerProps {
  referrer?: string;
  successHandler?: (tokenData: TokenData) => any;
}

export default function LoginContainer(props: LoginContainerProps): JSX.Element {
  // CONSTANT DECLARATION
  const history = useHistory();
  const login = useLogin();
  const { referrer, successHandler } = props;
  const { setToken } = useAuthorization();

  const handleNewToken = (tokenData: TokenResponse): any => {
    const payload: TokenData = jwtDecode(tokenData.token);
    setToken(tokenData.token);
    if (successHandler) {
      successHandler(payload);
    } else {
      history.push('/inventory');
      return payload;
    }
    if (referrer) {
      history.push(referrer);
      return;
    }
    return payload;
  };

  const handleLoginSubmit = async (request: TokenRequest): Promise<void> => {
    try {
      const response = await login.mutateAsync(request);
      if (response && !response.validationErrors) {
        const payload = handleNewToken(response);
        if (successHandler) {
          successHandler(payload);
        }
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  return (
    <UXComponent>
      <Web>
        <LoginFormWeb
          submitAction={handleLoginSubmit}
          validationSchema={schema}
          serverValidation={login?.error?.validationErrors}
        />
      </Web>
      <Ionic>
        <LoginFormIonic
          submitAction={handleLoginSubmit}
          validationSchema={schema}
          serverValidation={login?.error?.validationErrors}
        />
      </Ionic>
    </UXComponent>
  );
}
