import { Box } from 'components/baseElements/grid';
import React from 'react';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import { StyledTableRow } from '..';

const StyledTable = styled('table')`
  ${layout}
  ${props => props.isStriped && css`
    ${StyledTableRow}:nth-child(even) {
      background-color: rgba(128,23,71, 0.08);
    }
  `}
  ${props => props.hasRowHover && css`
    ${StyledTableRow}:hover {
      background: rgba(0,255,0, 0.3)
    }
  `}
`;

const StyledContainer = styled(Box)`
  /* width */
  ::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #c7b8ba;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(244,54,76, 0.65);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

interface StyledTableProps extends React.HTMLAttributes<HTMLFormElement>, LayoutProps {
  children?: React.ReactNode;
  hasRowHover?: boolean;
  isResponsive?: boolean;
  isStriped?: boolean;
  tableContainerProps?: any;
}

export function Table(props: StyledTableProps): JSX.Element {
  const {
    children,
    hasRowHover,
    isResponsive = true,
    isStriped,
    role = 'table',
    tableContainerProps,
    width = 1,
    ...otherProps
  } = props;

  function Container(props: StyledTableProps): JSX.Element {
    const {
      children,
      isResponsive,
    } = props;

    if (isResponsive) {
      return (
        <StyledContainer
          border="2px solid rgba(128,23,71, 0.75)"
          borderRadius={4}
          overflow="auto"
          width={width}
          {...tableContainerProps}
        >
          {children}
        </StyledContainer>
      )
    }
    return <>{children}</>;
  }

  return (
    <Container isResponsive={isResponsive}>
      <StyledTable
        hasRowHover={hasRowHover}
        isStriped={isStriped}
        role={role}
        width={width}
        {...otherProps}
      >
        {children}
      </StyledTable>
    </Container>
  );
}
