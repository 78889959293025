import styled from 'styled-components';
import { color, layout, space, typography, variant } from 'styled-system';

const Heading = styled('h2')`
  ${color}
  ${layout}
  ${space}
  ${typography}
  ${variant({
    prop: 'headingSize',
    variants: {
      '2xl': {
        fontSize: 30,
      },
      'xl': {
        fontSize: 28,
      },
      'lg': {
        fontSize: 26,
      },
      'md': {
        fontSize: 23,
      },
      'sm': {
        fontSize: 21,
      },
      'xs': {
        fontSize: 18,
      },
    }
  })}
`;

Heading.defaultProps = {
  color: 'var(--ion-text-color)',
  fontFamily: 'var(--title-font-family)',
  fontWeight: 'bold',
  lineHeight: 1.2,
  size: 'xl',
};

export { Heading };
