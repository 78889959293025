import { IonContent, IonFooter, IonTitle, IonToolbar } from '@ionic/react';
import styled from 'styled-components';

export const AppContent = styled(IonContent)`
  --padding-bottom: env(safe-area-inset-bottom);
  --padding-left: env(safe-area-inset-left);
  --padding-right: env(safe-area-inset-right);
`;
//use this suggested style to stop flickering on the header https://github.com/ionic-team/ionic-framework/issues/17494
export const AppToolbar = styled(IonToolbar)`
  --background: linear-gradient(to left, #7d172f, #4b2326);
`;

export const AppFooter = styled(IonFooter)`
  background: #ffffff;
`;

export const AppTitle = styled(IonTitle)`
  /* Page Title */

  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 145%;
  /* or 26px */

  /* White */
  color: #ffffff;
  text-align: center;
`;

// This is used in place of IonPage, copying the CSS when IonPage caused routerLink navigation not to work and/or
// refresh navigation several times (flickering screen).
export const StyledIonPage = styled('div')`
  background: linear-gradient(#7d172f, #4b2326);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;
