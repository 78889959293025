import { IonCard, IonCardContent } from '@ionic/react';
import { Ionic, UXComponent, Web } from 'napa-react-core';
import Header from 'components/baseElements/Header';
import UserProfileAdditionalOptions from 'components/users/UserProfileAdditionalOptions';
import UserProfileFormIonic from 'components/users/UserProfileForm/ionic';
import UserProfileFormWeb from 'components/users/UserProfileForm/web';
import useUserProfile from 'customHooks/users/useUserProfile';
import { Box, Flex } from '@napa/grape-ui-react';
import React from 'react';
import { schema } from './validation';

export default function UserProfileContainer(): JSX.Element {
  const { data: userData, isLoading: fetchIsLoading } = useUserProfile();
  return (
    <UXComponent>
      <Web>
        <Header pageTitle="Profile" />
        <Flex justifyContent="center" flexDirection="column" height="100%">
          <Box flex={1} mb={70} mx="auto" maxWidth={600} width={1}>
            <IonCard>
              <IonCardContent>
                <UserProfileFormWeb
                  user={userData}
                  dataIsLoading={fetchIsLoading}
                  validationSchema={schema}
                />
                <UserProfileAdditionalOptions />
              </IonCardContent>
            </IonCard>
          </Box>
        </Flex>
      </Web>
      <Ionic>
        <UserProfileFormIonic
          user={userData}
          dataIsLoading={fetchIsLoading}
          validationSchema={schema}
        />
      </Ionic>
    </UXComponent>
  );
}
