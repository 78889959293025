/* eslint-disable @typescript-eslint/no-var-requires */
import { Style } from '@capacitor/status-bar';
import { setupConfig } from '@ionic/core';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import RoutesContainer from 'containers/routes/RoutesContainer';
import flatten from 'flat';
import messagesEn from 'localization/en.json';
import {
  AppSettingsProvider,
  AuthorizationProvider,
} from '@napa/react-core';
import {
  AppSettingsProvider as LegacyAppSettingsProvider,
  ActionSheetProvider,
  AlertProvider,
  ModalProvider,
  ToastProvider,
} from 'napa-react-core';
import { AppStateProvider } from 'providers/appState';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AppUrlListener from './AppUrlListener';
import { useStatusBar } from './customHooks/capacitor';

/* Theme variables */
import './theme/variables.css';

const envSettings = (window as any).ENV.ENVIRONMENT;
const messages: { [key: string]: any } = {
  en: flatten(messagesEn),
};

if (envSettings.componentType === 'web') {
  setupConfig({
    animated: false,
  });
}

const language = navigator.language.split(/[-_]/)[0];
const queryClient = new QueryClient();
queryClient.setDefaultOptions({ queries: { refetchOnWindowFocus: false, retry: false } });
const customBaseFontFamilyTheme = {
  fonts: {
    base: 'Nunito Sans, sans-serif',
  },
};

const App: React.FC = () => {
  useStatusBar(Style.Dark);
  return (
    <IonApp>
      <ThemeProvider theme={customBaseFontFamilyTheme}>
        <IntlProvider locale={language} messages={messages[language] || messages['en']}>
          <AppSettingsProvider environment={envSettings}>
            <LegacyAppSettingsProvider environment={envSettings}>
              <AppStateProvider>
                <AuthorizationProvider environmentSettings={envSettings}>
                  <QueryClientProvider client={queryClient} contextSharing={true}>
                    <ToastProvider>
                      <AlertProvider>
                        <ActionSheetProvider>
                          {envSettings.componentType === 'ionic' ? (
                            <IonReactRouter>
                              <ModalProvider forceIonic={true}>
                                <AppUrlListener baseUrl={envSettings['baseUrl']}/>
                                <RoutesContainer/>
                              </ModalProvider>
                            </IonReactRouter>
                          ) : (
                            <BrowserRouter>
                              <ModalProvider forceIonic={true}>
                                <RoutesContainer/>
                              </ModalProvider>
                            </BrowserRouter>
                          )}
                        </ActionSheetProvider>
                      </AlertProvider>
                    </ToastProvider>
                  </QueryClientProvider>
                </AuthorizationProvider>
              </AppStateProvider>
            </LegacyAppSettingsProvider>
          </AppSettingsProvider>
        </IntlProvider>
      </ThemeProvider>
    </IonApp>
  );
};
export default App;
