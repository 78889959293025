import { App, URLOpenListenerEvent } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface AppUrlListenerProps {
  baseUrl: string;
}

const AppUrlListener: React.FC<any> = (props: AppUrlListenerProps) => {
  const { baseUrl } = props;
  const history = useHistory();

  useEffect(() => {
    if (!baseUrl) {
      return;
    }
    const appListenerPromise: Promise<PluginListenerHandle> = App.addListener(
      'appUrlOpen',
      (data: URLOpenListenerEvent) => {
        // Remove the domain from the link to route in React
        const slug = data.url.split(baseUrl)[1];
        if (slug) {
          setTimeout(() => {
            // Push to the route now
            history.push(slug);
          }, 1000); // Helps cold start
        }
      },
    );
    return (): void => {
      appListenerPromise.then((handle: PluginListenerHandle) => {
        // eslint-disable-next-line no-console
        handle.remove().catch(console.error);
      });
    };
  }, [baseUrl, history]);

  return null;
};

export default AppUrlListener;
