import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonLabel, isPlatform } from '@ionic/react';
import { Flex } from 'components/baseElements/grid';
import React from 'react';
import styled from 'styled-components';

interface TabLabelProps {
  children: any;
  icon: any;
}

const Label = styled(IonLabel)`
  font-size: ${isPlatform('android') ? '12px' : '10px'};
  margin-top: 0px;
  margin-bottom: 6px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: ${isPlatform('android') ? '18px' : '26px'};
`;

export function TabLabel(props: TabLabelProps): JSX.Element {
  const { children, icon } = props;
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        mb={isPlatform('android') ? '2px' : '4px'}
        mt="6px"
        size={isPlatform('android') ? 22 : 30}
      >
        <Icon
          icon={icon}
        />
      </Flex>
      <Label>{children}</Label>
    </>
  );
}
