import { DeviceInfo, Device } from '@capacitor/device';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useEffect } from 'react';

export function useStatusBar(style: Style): void {
  useEffect(() => {
    Device.getInfo().then((deviceInfo: DeviceInfo) => {
      // Run this code only on Android and iOS to prevent filling the console with errors while developing.
      if (deviceInfo.platform === 'ios' || deviceInfo.platform === 'android') {
        StatusBar.setStyle({ style })
          // eslint-disable-next-line no-console
          .catch(console.error);
      }
    });
  }, [style]);
}
