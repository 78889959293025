import { IonCard, IonCardContent } from '@ionic/react';
import { useQueryString } from '@napa/react-core';
import { Ionic, UXComponent, Web } from 'napa-react-core';
import { Box, Flex } from 'components/baseElements/grid';
import Header from 'components/baseElements/Header';
import ResetPasswordFormIonic from 'components/users/ResetPasswordForm/ionic';
import ResetPasswordFormWeb from 'components/users/ResetPasswordForm/web';
import useChangePassword from 'customHooks/users/useChangePassword';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ChangePasswordRequest } from 'types/users';
import { schema } from './validation';

export default function ResetPasswordContainer(): JSX.Element {
  const qs = useQueryString();
  const resetToken = qs.get('token') || '';
  const email = qs.get('email') || '';
  const history = useHistory();
  const changePassword = useChangePassword({
    successHandler: () => history.push('/landing'),
  });

  const handleChangePasswordSubmit = async (data: ChangePasswordRequest): Promise<void> => {
    data.token = resetToken;
    data.email = email;
    data.currentPassword = undefined;
    try {
      await changePassword.mutateAsync(data);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <UXComponent>
      <Web>
        <Flex flexDirection="column">
          <Header pageTitle="Reset Password" />
          <Box maxWidth={600} mx="auto" width={1}>
            <IonCard>
              <IonCardContent>
                <ResetPasswordFormWeb
                  submitAction={handleChangePasswordSubmit}
                  showExistingPassword={false}
                  serverValidation={changePassword?.error?.validationErrors}
                  validationSchema={schema}
                />
              </IonCardContent>
            </IonCard>
          </Box>
        </Flex>
      </Web>
      <Ionic>
        <ResetPasswordFormIonic
          submitAction={handleChangePasswordSubmit}
          serverValidation={changePassword?.error?.validationErrors}
          validationSchema={schema}
        />
      </Ionic>
    </UXComponent>
  );
}
