import { CustomHookProps, useBasicQuery } from '@napa/react-core';
import { User } from 'types/users';

export default function useUserDetails(userId: string, props?: CustomHookProps<User>): any {
  return useBasicQuery<User>({
    apiRoute: `users/${userId}`,
    httpMethod: 'GET',
    isFormData: false,
    body: undefined,
    queryId: ['userDetails', userId],
    params: props,
  });
}
