import { useDataList } from 'napa-react-core';
import SortIndicator from 'components/baseElements/DataList/SortIndicator';
import Paginator from 'components/baseElements/Paginator';
import { TableRow } from 'components/baseElements/tableComponents';
import { Box, Flex } from '@napa/grape-ui-react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { User } from 'types/users';
import UserItemInline from '../UserItemInline';
import {
  FilterBox,
  FilterTextInput,
  GridBody,
  GridHeader,
  HeaderCol,
  SortBox,
  TableOuterBox,
  UserTable,
} from './styles';

interface UserListProperties {
  userItems?: Array<User>;
  userClickHandler?: (user: User) => any;
}

export default function UserList(props: UserListProperties): JSX.Element {
  const { userItems, userClickHandler } = props;
  const scrollableBox = React.createRef();
  const itemRender = (item: User, key: any) => (
    <TableRow onClick={() => userClickHandler && userClickHandler(item)} key={key}>
      <UserItemInline user={item} />
    </TableRow>
  );
  const {
    applyFilter,
    applySort,
    currentPage,
    filterCriteria,
    filteredItemCount,
    maxPages,
    paginationControls,
    setItems,
    renderedItems,
    sortCriteria,
    totalItemCount,
  } = useDataList({
    itemRender: itemRender,
    defaultSort: { field: 'email', direction: 'asc' },
    pageSize: 500,
  });

  useEffect(() => {
    setItems(userItems || []);
  }, [setItems, userItems]);

  return (
    <>
      {userItems && userItems.length ? (
        <Flex flexDirection="column" height="100%" justifyContent="flex-start">
          <Box mb="5px">
            <Flex flexDirection={['column', 'row']} justifyContent={[null, 'space-between']}>
              <Box alignSelf={[null, 'center']}>
                <Flex flexDirection="column">
                  <Box>Total items: {totalItemCount}</Box>
                  <Box>Items Matching Filter: {filteredItemCount}</Box>
                </Flex>
              </Box>
              <Paginator
                currentPage={currentPage}
                maxPages={maxPages}
                controls={paginationControls}
              />
            </Flex>
          </Box>
          <TableOuterBox>
            <UserTable>
              <GridHeader>
                <TableRow>
                  <HeaderCol>
                    <Flex flexDirection="column">
                      <SortBox onClick={(): void => applySort('email')}>
                        <FormattedMessage id="users.table.emailHeaderLabel"></FormattedMessage>
                        <SortIndicator
                          fieldName="email"
                          currentSortName={sortCriteria.field}
                          currentSortDirection={sortCriteria.direction}
                        ></SortIndicator>
                      </SortBox>
                      <FilterBox>
                        <FilterTextInput
                          placeholder="Filter..."
                          isActive={filterCriteria.email}
                          onChange={(e: any): void => applyFilter('email', e.target.value)}
                        ></FilterTextInput>
                      </FilterBox>
                    </Flex>
                  </HeaderCol>
                  <HeaderCol>
                    <Flex flexDirection="column">
                      <SortBox onClick={(): void => applySort('apcId')}>
                        APC ID
                        <SortIndicator
                          fieldName="apcId"
                          currentSortName={sortCriteria.field}
                          currentSortDirection={sortCriteria.direction}
                        ></SortIndicator>
                      </SortBox>
                      <FilterBox>
                        <FilterTextInput
                          placeholder="Filter..."
                          isActive={filterCriteria.apcId}
                          onChange={(e: any): void => applyFilter('apcId', e.target.value)}
                        ></FilterTextInput>
                      </FilterBox>
                    </Flex>
                  </HeaderCol>
                  <HeaderCol>
                    <Flex flexDirection="column">
                      <SortBox onClick={(): void => applySort('isAdmin')}>
                        Is Admin
                        <SortIndicator
                          fieldName="isAdmin"
                          currentSortName={sortCriteria.field}
                          currentSortDirection={sortCriteria.direction}
                        ></SortIndicator>
                      </SortBox>
                    </Flex>
                  </HeaderCol>
                </TableRow>
              </GridHeader>
              <GridBody ref={scrollableBox}>{renderedItems}</GridBody>
            </UserTable>
          </TableOuterBox>
        </Flex>
      ) : undefined}
    </>
  );
}
