import { InventoryItem } from '../../types/inventory';

export const getInventoryEstimateText = (item?: InventoryItem, withEst?: boolean): string => {
  // No item or no estimates
  if (!item || !item.lowEstimate || !item.highEstimate) {
    return '';
  }
  // Both low and high estimates
  if (!!item.lowEstimate && !!item.highEstimate) {
    if (item.lowEstimate === item.highEstimate) {
      return `${withEst ? '• EST ' : ''}$${item.lowEstimate}`;
    }
    return `${withEst ? '• EST ' : ''}$${item.lowEstimate} - $${item.highEstimate}`;
  }
  // Both low or high estimates
  return `${withEst ? '• EST ' : ''}$${item.lowEstimate || item.highEstimate}`;
};

export const getInventoryFullName = (item: InventoryItem): string => {
  if (!item) {
    return '';
  }
  return `${item.vintage || ''} ${item.producer || ''} ${item.wineName || ''}`;
};

export const getInventoryQuantityText = (item: InventoryItem): string => {
  if (!item) {
    return '';
  }
  return `${item.quantity} ${item.bottleName}${item.quantity === 1 ? '' : 's'}`;
};
