import { Box } from 'components/baseElements/grid';
import styled from 'styled-components';

export const HeaderCol = styled('th')`
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  background-color: #efefef;
`;

export const UserRow = styled('tr')`
  cursor: pointer;
  :hover {
    background: rgba(0,255,0, 0.3)
  }
`;

export const HeaderRow = styled('tr')``;

export const GridHeader = styled('thead')``;

export const GridBody = styled('tbody')``;

export const SortBox = styled(Box)`
  cursor: pointer;
  :hover {
    color: rgba(128,23,71, 0.8)
  }
`;

export const FilterBox = styled(Box)``;

export const FilterTextInput = styled('input')`
  ${(props): any => props.isActive && 'border: 2px solid rgba(128,23,71, 1);'}
  ${(props): any => !props.isActive && 'border: 2px solid rgba(128,23,71, 0.4);'}
  :focus {
    outline: none;
    border: 2px solid rgba(128,23,71, 0.8)
  }
  border-radius: 4px;
  padding: 6px 10px;
  margin: 8px 0;
  box-sizing: border-box;
  width: 100%;
`;

export const TableOuterBox = styled('div')`
  flex: 1 1 0;
  overflow-y: scroll;
  border: 2px solid rgba(128,23,71, 0.75);
  border-radius: 4px;
  /* width */
  ::-webkit-scrollbar {
    width: 15px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #c7b8ba;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(244,54,76, 0.65);
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const UserTable = styled('table')`
  & ${UserRow}:nth-child(even) {
    background-color: rgba(128,23,71, 0.08)
  }
  border-spacing: 0px;

  table-layout: fixed;
  width: 100%;
`;