import { faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppState } from 'providers/appState';
import { Hideable } from '@napa/ui-react';
import { useActionSheet } from 'napa-react-core';
import React from 'react';
import { Box } from '../../baseElements/grid';

interface InventorySortFilterProperties {
  applySort?: (field: string, direction?: 'asc' | 'desc') => void;
  onSortFilterChange?: (field: string) => void;
  sortCriteria: {
    field: string;
    direction: 'asc' | 'desc';
  };
}

export default function InventorySortFilter(props: InventorySortFilterProperties): JSX.Element {
  const { setWineSort } = useAppState();
  const { applySort, onSortFilterChange, sortCriteria } = props;
  const flipSortDirection = (d: 'asc' | 'desc'): 'asc' | 'desc' => (d === 'asc' ? 'desc' : 'asc');
  const handleChange = (nextField: string) => {
    setWineSort({ field: nextField,
      direction: nextField === sortCriteria.field
        ? flipSortDirection(sortCriteria.direction)
        : sortCriteria.direction});
    if (applySort) {
      applySort(
        nextField,
        nextField === sortCriteria.field
          ? flipSortDirection(sortCriteria.direction)
          : sortCriteria.direction,
      );
    }
    if (onSortFilterChange) {
      onSortFilterChange(nextField);
    }
  };
  const actionSheetButtons = [
    {
      text: 'Wine Name',
      handler: (): void => handleChange('wineName'),
    },
    //TODO: Temporarily Remove Quantity Sort, Put back when BE is working for Quantity Sort
    // {
    //   text: 'Quantity',
    //   handler: (): void => handleChange('quantity'),
    // },
    {
      text: 'Vintage',
      handler: (): void => handleChange('vintage'),
    },
    {
      text: 'Producer',
      handler: (): void => handleChange('producer'),
    },
  ];
  const getFieldName = (field: string): string => {
    switch (field) {
      case 'producer':
        return 'Producer';
      case 'quantity':
        return 'Quantity';
      case 'vintage':
        return 'Vintage';
      case 'wineName':
        return 'Wine Name';
      default:
        return 'Default';
    }
  };
  const { showActionSheet } = useActionSheet();
  return (
    <Box
      fontSize="12px"
      fontWeight={300}
      onClick={(): void => showActionSheet(actionSheetButtons)}
      py={2}
      px={3}
    >
      Sort By{' '}
      <span style={{ color: '#a21c3a', fontWeight: 600 }}>{getFieldName(sortCriteria?.field)}</span>{' '}
      <Hideable hide={!sortCriteria?.field}>
        <FontAwesomeIcon icon={sortCriteria.direction === 'asc' ? faArrowUp : faArrowDown} />
      </Hideable>
    </Box>
  );
}
