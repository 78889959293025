import * as yup from 'yup';

export const schema = yup.object().shape({
  currentPassword: yup.string().required('validation.requiredAssistiveText'),
  newPassword: yup
    .string()
    .required('validation.requiredAssistiveText')
    .min(8, 'api.validation.invalidPassword'),
  passwordConfirm: yup
    .string()
    .required('validation.requiredAssistiveText')
    .min(8, 'api.validation.invalidPassword')
    .test('passwordsMustMatch', 'validation.unmatchedPasswordsErrorLabel', function (): boolean {
      return this.parent.newPassword === this.parent.passwordConfirm;
    }),
});
