import { Box, Flex, Header } from '@napa/grape-ui-react';
import React from 'react';
import styled from 'styled-components';

const AboutUsSection = styled('section')`
  text-align: center;
  color: #ffffff;
  background-position: center left;
  background-image: url(${process.env.PUBLIC_URL}/assets/images/barrel.jpg);
  background-size: cover;
  align-items: center;
`;

const AboutUsOverlay = styled('div')`
  background-color: rgba(0, 0, 0, 0.35);
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  text-align: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const AboutUsBox = styled(Box)`
  width: 800px;
  background-color: white;
  border-radius: 20px;
  color: #000000;
  align-self: center;
  padding: 50px 50px 50px 50px;
  text-align: left;
`;

const AboutUs: React.FC = () => {
  return (
    <AboutUsSection>
      <AboutUsOverlay>
        <Header.h2 style={{ fontSize: '39px', fontWeight: 'bold' }}>ABOUT US</Header.h2>
        <Header.h5>Climate controlled wine storage and cellar management services</Header.h5>
        <Flex justifyContent="center">
          <AboutUsBox>
            <p>Acker Storage offers full-service, climate-controlled wine storage and cellar management services to wine enthusiasts and collectors in the United States and abroad.</p>
            <p>Whether you plan to enjoy your wine next year, or in 15 years, Acker Storage ensures your collection will age gracefully while keeping it secure from any environmental effects by providing the following services:</p>
            <ul>
              <li>Custom storage plans</li>
              <li>Constant cellar environment</li>
              <li>24/7 security monitoring</li>
              <li>Digital camera security</li>
              <li>Temperature alarm system monitoring</li>
              <li>Fire suppression systems</li>
              <li>Online Inventory management</li>
              <li>Global shipping and logistics assistance</li>
            </ul>
            </AboutUsBox>
          </Flex>
        </AboutUsOverlay >
    </AboutUsSection>
  );
}

export default AboutUs;
