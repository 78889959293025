import { InventoryItem } from 'types/inventory';
import React from 'react';
import { IonContent, IonList } from '@ionic/react';
import { UXComponent, Ionic } from 'napa-react-core';
import { FormattedDate, FormattedTime } from 'react-intl';
import { DetailNameHeader, DetailNameTitle } from './styles';
import { getInventoryEstimateText } from 'utils/inventory';
import { Box, Flex } from 'components/baseElements/grid';
import { Caption } from 'components/baseElements/typography/Caption';

interface InventoryDetailProperties {
  inventoryItem?: InventoryItem;
}

export default function InventoryDetail(props: InventoryDetailProperties): JSX.Element {
  const { inventoryItem } = props;
  const ionItemGridRender = (label: string, value: any): JSX.Element => (
    <Flex alignItems="baseline" px={3} pt={1}>
      <Caption style={{ textAlign: 'right' }} minWidth={130} width={130}>
        {label}
      </Caption>
      <Box fontSize="14px" fontWeight={200} pl={2}>
        {value}
      </Box>
    </Flex>
  );
  return (
    <UXComponent>
      <Ionic>
        <IonContent>
          <DetailNameHeader>
            <DetailNameTitle>
              {inventoryItem?.vintage} {inventoryItem?.producer} {inventoryItem?.wineName}
            </DetailNameTitle>
          </DetailNameHeader>
          <IonList>
            {ionItemGridRender('Available Quantity', inventoryItem?.quantity)}
            {ionItemGridRender('Bottle Size', inventoryItem?.bottleName)}
            {ionItemGridRender('Vintage', inventoryItem?.vintage)}
            {ionItemGridRender('Wine Name', inventoryItem?.wineName)}
            {ionItemGridRender('Producer', inventoryItem?.producer)}
            {ionItemGridRender('Designation', inventoryItem?.designation)}
            {ionItemGridRender('Appellation', inventoryItem?.appellation)}
            {ionItemGridRender('Region', inventoryItem?.regionDescription)}
            {ionItemGridRender('Condition', inventoryItem?.condition)}
            {ionItemGridRender('Box', inventoryItem?.boxCode)}
            {ionItemGridRender('Source', inventoryItem?.supplier)}
            {ionItemGridRender('Location', inventoryItem?.locAbbr)}
            {ionItemGridRender('Estimate', getInventoryEstimateText(inventoryItem, false))}
            {ionItemGridRender(
              'Latest Addition',
              <>
                <FormattedDate value={inventoryItem?.whCreateDt} />
                &nbsp;
                <FormattedTime value={inventoryItem?.whCreateDt} />
              </>,
            )}
          </IonList>
        </IonContent>
      </Ionic>
    </UXComponent>
  );
}
