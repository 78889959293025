import { CustomHookProps, useBasicMutation } from '@napa/react-core';
import { User } from 'types/users';

export default function useDeleteUser(userId: string, props?: CustomHookProps<User>): any {
  return useBasicMutation<User>({
    apiRoute: `users/${userId}`,
    httpMethod: 'DELETE',
    isFormData: false,
    params: props,
  });
}
