/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import LoginContainer from 'containers/authorization/LoginContainer';
import { IonContent } from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import ForgotPasswordContainer from 'containers/users/ForgotPasswordContainer';
import { TokenData } from '@napa/react-core';
import { Box, Flex } from 'components/baseElements/grid';
import { useToast } from 'napa-react-core';

export const AuthorizationModalId = 'authorization';

interface AuthorizationProcessContainerProps {
  successHandler: (payload: TokenData) => any;
}

export default function AuthorizationProcessContainer(
  props: AuthorizationProcessContainerProps,
): JSX.Element {
  const toast = useToast();
  const intl = useIntl();
  const [mode, setMode] = React.useState('login');
  const { successHandler } = props;

  return mode === 'registration' ? (
    <IonContent>
      {/* <IonToolbar color="light">
          <IonGrid>
            <IonRow className="ion-align-items-center ion-justify-content-evenly">
              <Paragraph as="small">
                <FormattedMessage id="authorizationProcessContainer.alreadyHasAccountBlurb" />
              </Paragraph>
              <GrapeButton
                color="secondary"
                data-cy="loginButton"
                onClick={(): void => setMode('login')}
              >
                <FormattedMessage id="authorizationProcessContainer.loginButtonLabel" />
              </GrapeButton>
            </IonRow>
          </IonGrid>
        </IonToolbar>
        <UserRegistrationContainer autoLogin={true} successHandler={successHandler} /> */}
    </IonContent>
  ) : mode === 'forgotPassword' ? (
    <IonContent>
      <Box maxWidth={600} mx="auto">
        <ForgotPasswordContainer
          successHandler={(): void => {
            toast.showToast({
              message: intl.formatMessage({ id: 'forgotPasswordForm.toasts.success' }),
            });
            setMode('login');
          }}
        />
        <Flex justifyContent="center">
          <a onClick={(): void => setMode('login')} color="secondary">
            <FormattedMessage id="authorizationProcessContainer.alreadyHasAccountButtonLabel" />
          </a>
        </Flex>
      </Box>
    </IonContent>
  ) : (
    <IonContent>
      <Box maxWidth={600} mx="auto">
        <LoginContainer successHandler={successHandler} />
        <Flex justifyContent="center">
          <a onClick={(): void => setMode('forgotPassword')} color="secondary">
            <FormattedMessage id="authorizationProcessContainer.forgotPasswordButtonLabel" />
          </a>
        </Flex>
      </Box>
    </IonContent>
  );
}
