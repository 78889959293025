import { CustomHookProps, useBasicQuery } from '@napa/react-core';
import { InventoryItem } from 'types/inventory';
import queryStringHelper from 'query-string';

export interface SearchInventoryQueryParams {
  apcId?: string;
  filter?: number;
  limit?: number;
  offset?: number;
  sortByAndDirection?: string;
  searchText?: string;
}
export default function useInventory(
  params: SearchInventoryQueryParams,
  props?: CustomHookProps<InventoryItem>,
): any {
  const { apcId } = params;
  const queryParams = queryStringHelper.stringify(params);
  return useBasicQuery<InventoryItem>({
    apiRoute: `inventory/byClient?${queryParams}`,
    httpMethod: 'GET',
    isFormData: false,
    body: undefined,
    queryId: ['inventory', apcId],
    params: props,
  });
}
