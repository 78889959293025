import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {useAuthorization} from '@napa/react-core';

interface PrivateRouteProps {
  component: React.FC;
  path: string;
  exact: boolean;
}
const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
  const { isLoggedIn } = useAuthorization();

  return isLoggedIn ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/landing" />
  );
};
export default PrivateRoute;
