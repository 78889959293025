import styled from 'styled-components';
import { color, layout, space, typography, system } from 'styled-system';

const Paragraph = styled('p')`
  ${color}
  ${layout}
  ${space}
  ${typography}
  ${system({ textTransform: true, letterSpacing: true })}
`;

Paragraph.defaultProps = {
  fontWeight: 300,
  lineHeight: 1.25,
};

export { Paragraph };
