import { AuthorizationStore } from '@napa/react-core';
import { CustomHookProps, useBasicQuery } from '@napa/react-core';
import { useContext } from 'react';
import { User } from 'types/users';

export default function useUserProfile(props?: CustomHookProps<User>): any {
  const authData = useContext(AuthorizationStore);
  return useBasicQuery<User>({
    apiRoute: `users/${authData.state.tokenData?.['userData/userId'] || ''}`,
    httpMethod: 'GET',
    isFormData: false,
    body: undefined,
    queryId: ['userProfile', authData.state.tokenData?.['userData/userId']],
    params: props,
  });
}
