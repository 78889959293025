import { useState } from 'react';
export const localStorageKeys = {
  browseInventoryItemsFilter: 'BROWSE_INVENTORY_ITEMS_FILTER',
};
export function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => boolean, () => T] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const getValue = (): T => {
    const item = window.localStorage.getItem(key);
    const itemObject = item ? JSON.parse(item) : initialValue;
    setStoredValue(itemObject);
    return itemObject;
  };

  const setValue = (value: T): boolean => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return false;
    }
  };

  return [storedValue, setValue, getValue];
}
