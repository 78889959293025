import React from 'react';

export const ToolbarLogo: React.FC = () => {
  const AckerStorageBrandImg = `${process.env.PUBLIC_URL}assets/images/AckerStorage-07.png`;
  return (
    <img
      alt=""
      style={{
        borderStyle: 'none',
        display: 'block',
        height: '60px',
        margin: '16px auto',
        width: '135px',
      }}
      src={AckerStorageBrandImg}
    />
  );
};
