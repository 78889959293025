import { Box, Flex, Image, Text } from '@napa/grape-ui-react';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BannerSection = styled('section')`
  background-image: url(${process.env.PUBLIC_URL}/assets/images/barrels.jpg);
  background-size: cover;
  margin-bottom: 20px;
`;

const BannerOverlay = styled('div')`
  background-color: rgba(128,23,71, 0.75);
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
`;

const PageTitle = styled(Text)`
  text-transform: uppercase;
  color: #fff;
  display: block;
  font-size: calc(32px + 6 * ((100vw - 320px) / 680));
  font-weight: bold;
`;

interface BigHeaderProps {
  pageTitle?: string;
}

const BigHeader: React.FC<BigHeaderProps> = (props: BigHeaderProps) => {
  const { pageTitle } = props;
  return (
    <BannerSection>
      <BannerOverlay>
        <Flex alignItems="center" flexDirection={['column', 'row']} justifyContent={['center', 'space-evenly']} p={4}>
          <Link to={'/landing'}>
            <Image
              alt="logo"
              maxHeight="70px"
              maxWidth="350px"
              p={1}
              src={`${process.env.PUBLIC_URL}/assets/images/AckerStorage-07.png`}
              width="auto"
            />
          </Link>
          <Box alignSelf="center" style={{ textAlign: 'center'}}>
            <PageTitle>{pageTitle}</PageTitle>
          </Box>
        </Flex>
      </BannerOverlay>
    </BannerSection>
  );
}

export default BigHeader;
