import { yupResolver } from '@hookform/resolvers/yup';
import { IonInput, IonList, IonToggle } from '@ionic/react';
import { Hideable } from '@napa/ui-react';
import { handleFormDataReset, handleServerHookForm } from '@napa/react-core';
import { GrapeButton } from 'napa-react-core';
import HookInput from 'components/baseElements/formControls/HookInput';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { User } from 'types/users';

interface UserDetailsFormProperties {
  submitAction?: (data: any) => any;
  dataIsLoading?: boolean;
  serverValidation?: any;
  showPassword?: boolean;
  validationSchema?: any;
  user?: User;
}

export default function UserDetailsForm(props: UserDetailsFormProperties): JSX.Element {
  const {
    submitAction = () => {
      return;
    },
    serverValidation,
    validationSchema,
    dataIsLoading,
    showPassword = false,
    user,
  } = props;

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
      isAdmin: false,
      apcId: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const isAdmin = form.watch('isAdmin');

  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  useEffect(() => {
    handleFormDataReset(user, form.reset);
  }, [user, form.reset]);

  return (
    <>
      <form onSubmit={form.handleSubmit(submitAction)} noValidate>
        <IonList lines="full">
          <HookInput form={form} isRequired labelText="userDetailsForm.emailLabel" name="email">
            <IonInput disabled={dataIsLoading} />
          </HookInput>
          <Hideable hide={!showPassword}>
            <HookInput
              assistiveText="userDetailsForm.passwordAssistiveText"
              form={form}
              isRequired
              labelText="userDetailsForm.passwordLabel"
              name="password"
            >
              <IonInput disabled={dataIsLoading} />
            </HookInput>
          </Hideable>
          <HookInput
            form={form}
            name="isAdmin"
            labelPosition="default"
            labelText="userDetailsForm.isAdminLabel"
          >
            <IonToggle checked={false} />
          </HookInput>
          <Hideable hide={isAdmin}>
            <HookInput form={form} labelText="userDetailsForm.apcIdLabel" name="apcId">
              <IonInput disabled={dataIsLoading} />
            </HookInput>
          </Hideable>
          <GrapeButton expand="block" type="submit">
            <FormattedMessage id="userDetailsForm.saveButtonLabel" />
          </GrapeButton>
        </IonList>
      </form>
    </>
  );
}
