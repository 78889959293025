import { IonText, IonRow, IonCol } from '@ionic/react';
import { Flex } from 'components/baseElements/grid';
import styled from 'styled-components';

export const DetailTitle = styled(Flex).attrs({ p: 3 })`
  font-size: 20px;
  font-weight: 300;
`;

export const DetailLabelCol = styled(IonCol)`
* Auto Layout */
display: flex;
flex-direction: row;
align-items: baseline;
text-align: right;
`;

export const DetailValueCol = styled(IonCol)`
* Auto Layout */
display: flex;
flex-direction: row;
`;

export const DetailRow = styled(IonRow)`
* Auto Layout */
display: flex;
flex-direction: row;
`;

export const DetailLabel = styled(IonText)`
  /* Caption */

  font-family: Inter;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 190%;
  text-align: right;
  letter-spacing: 0.1em;

  /* Sepia/900 */

  color: #3b2827;
`;

export const DetailValue = styled(IonText)`
  /* Paragraph Small */

  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  line-height: 145%;
  font-size: 15px;

  /* Sepia/900 */

  color: #3b2827;
`;

export const DetailNameHeader = styled(Flex).attrs({
  justifyContent: 'center',
  px: 3,
  py: 2,
})`
  background: #fcf9f9;
`;

export const DetailNameTitle = styled(IonText)`
  position: static;

  /* Page Title */

  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-size: 17px;
  line-height: 145%;
  /* or 26px */

  text-align: center;

  /* Sepia/900 */

  color: #3b2827;
`;
