/* eslint react/prop-types: 0 */
import { ErrorMessage } from '@hookform/error-message';
import { IonItem, IonLabel } from '@ionic/react';
import AssistiveText from 'components/baseElements/formControls/AssistiveText';
import React, { FunctionComponent } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

type HookInputProps = {
  form: UseFormMethods<any>;
  isRequired?: boolean;
  name?: any;
  assistiveText?: string;
  labelText?: string;
  defaultValue?: any;
  labelPosition?: 'default' | 'floating' | 'fixed' | 'stacked' | undefined;
  additionalChangeHandler?: (e: any) => void;
};

const HookInput: FunctionComponent<HookInputProps> = (props) => {
  const {
    assistiveText,
    defaultValue,
    form,
    isRequired,
    labelPosition = 'floating',
    labelText,
    name,
  } = props;

  const children: any = React.Children.toArray(props.children);

  return (
    <>
      <IonItem>
        {labelText ? (
          <IonLabel position={labelPosition === 'default' ? undefined : labelPosition}>
            <FormattedMessage id={labelText} />
            {isRequired ? '*' : undefined}
          </IonLabel>
        ) : (
          ''
        )}
        <Controller
          control={form.control}
          name={name}
          defaultValue={defaultValue}
          render={({ onChange, value }): any =>
            React.cloneElement(props.children as any, {
              onIonChange: (e: any): void => {
                if (children[0].props.onIonChange) {
                  children[0].props.onIonChange(e);
                }
                if (e.detail.checked !== undefined) {
                  onChange(e.detail.checked);
                } else {
                  onChange(e.detail.value);
                }
              },
              checked: children[0].props.checked !== undefined ? value : undefined,
              value: value,
            })
          }
        />
      </IonItem>
      <ErrorMessage
        errors={form.errors}
        name={name}
        render={({ message }): React.ReactNode => (
          <AssistiveText color="danger" data-cy={`${name}ValidationMessage`}>
            <FormattedMessage id={message} />
          </AssistiveText>
        )}
      />
      {!assistiveText && isRequired ? (
        <AssistiveText data-cy={`${name}AssistiveTextRequired`}>
          <FormattedMessage id="validation.requiredAssistiveText" />
        </AssistiveText>
      ) : undefined}
      {assistiveText ? (
        <AssistiveText data-cy={`${name}AssistiveText`}>
          <FormattedMessage id={assistiveText} />
        </AssistiveText>
      ) : undefined}
    </>
  );
};

export default HookInput;
