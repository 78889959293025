import { CustomHookProps, useBasicMutation } from '@napa/react-core';
import { ChangePasswordRequest } from 'types/users';

export default function useChangePassword(props?: CustomHookProps<ChangePasswordRequest>): any {
  return useBasicMutation<ChangePasswordRequest>({
    apiRoute: 'users/changePassword',
    httpMethod: 'PUT',
    isFormData: false,
    params: props,
  });
}
