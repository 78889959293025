import React from 'react';
import styled from 'styled-components';
import { User } from 'types/users';

const UserAttr = styled('td')`
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 2px solid rgba(128,23,71, 0.15);
  ${(props): any => props.centerContent && 'text-align: center'}
  ${(props): any => props.rightAlignContent && 'text-align: right'}
`;

interface UserItemInlineProperties {
  user: User;

}

export default function UserItemInline(props: UserItemInlineProperties): JSX.Element {
  const { user } = props;
  return (
    <>
      <UserAttr>{user.email}</UserAttr>
      <UserAttr>{user.apcId}</UserAttr>
      <UserAttr centerContent>{user.isAdmin ? 'Yes' : 'No'}</UserAttr>
    </>
  );
}
