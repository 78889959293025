import { CustomHookProps, useBasicMutation } from '@napa/react-core';
import { ForgotPasswordRequest } from 'types/users';

export default function useRequestPasswordChange(
  props?: CustomHookProps<ForgotPasswordRequest>,
): any {
  return useBasicMutation<ForgotPasswordRequest>({
    apiRoute: 'users/changePasswordRequest',
    httpMethod: 'POST',
    isFormData: false,
    params: props,
  });
}
