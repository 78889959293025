import UserDetailsForm from 'components/users/UserDetailsForm';
import useInsertUser from 'customHooks/users/useInsertUser';
import React from 'react';
import { User } from 'types/users';
import { schema } from './validation';

export interface CreateUserContainerProps {
  successHandler?: (user: User) => any;
}

export default function CreateUserContainer(props: CreateUserContainerProps): JSX.Element {
  const { successHandler } = props;
  const insertUser = useInsertUser();

  const saveUser = async (data: User): Promise<void> => {
    try {
      const response = await insertUser.mutateAsync(data);
      if (response && !response.validationErrors) {
        if (successHandler) {
          successHandler(data);
        }
      }
    }
    catch(error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <>
      <UserDetailsForm
        validationSchema={schema}
        serverValidation={insertUser?.error?.validationErrors}
        submitAction={saveUser}
        showPassword
      />
    </>
  );
}
