import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface SortIndicatorProps {
  fieldName: string;
  currentSortName: string;
  currentSortDirection: string;
}

export default function SortIndicator(props: SortIndicatorProps): JSX.Element {
  const { fieldName, currentSortName, currentSortDirection } = props;
  if (fieldName === currentSortName) {
    if (currentSortDirection === 'asc')
      return (<FontAwesomeIcon icon={faSortUp} style={{ paddingLeft: '2px'}} />);
    else
      return (<FontAwesomeIcon icon={faSortDown} style={{ paddingLeft: '2px' }} />);
  }

  return (<></>);
}
