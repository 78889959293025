import { Box, Flex } from '@chakra-ui/react';
import { IonContent } from '@ionic/react';
import { StyledIonPage } from 'containers/page/styles';
import { useAuthorization } from '@napa/react-core';
import { Ionic, UXComponent, Web } from 'napa-react-core';
import React, { useEffect } from 'react';
import RiseLoader from 'react-spinners/RiseLoader';

export default function LogoutContainer(): JSX.Element {
  const { removeToken } = useAuthorization();

  useEffect(() => {
    if (!!removeToken) {
      setTimeout(() => {
        removeToken();
        window.location.href = '/login';
      }, 500);
    }
  }, [removeToken]);
  return (
    <UXComponent>
      <Web>
        <Flex flexDirection="column">
          <Box alignSelf="center" alignItems="center" justifyContent="center" pt="150px" pb="50px">
            <RiseLoader size={50} color={'rgb(128,23,71)'} loading={true} />
          </Box>
        </Flex>
      </Web>
      <Ionic>
        <StyledIonPage>
          <IonContent style={{ '--background': 'linear-gradient(#7d172f, #4b2326)' }}>
            &nbsp;
          </IonContent>
        </StyledIonPage>
      </Ionic>
    </UXComponent>
  );
}
