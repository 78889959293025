import { Ionic, UXComponent, Web } from 'napa-react-core';
import ForgotPasswordFormIonic from 'components/users/ForgotPasswordForm/ionic';
import ForgotPasswordFormWeb from 'components/users/ForgotPasswordForm/web';
import useRequestPasswordChange from 'customHooks/users/useRequestPasswordChange';
import React from 'react';
import { ForgotPasswordRequest } from 'types/users';
import { schema } from './validation';

interface ForgotPasswordContainerProps {
  successHandler?: () => any;
}

export default function ForgotPasswordContainer(props: ForgotPasswordContainerProps): JSX.Element {
  const { successHandler } = props;

  const requestPasswordChange = useRequestPasswordChange({
    successHandler: () => successHandler && successHandler(),
  });
  const handleForgotPasswordSubmit = async (data: ForgotPasswordRequest): Promise<void> => {
    try {
      await requestPasswordChange.mutateAsync(data);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  return (
    <UXComponent>
      <Web>
        <ForgotPasswordFormWeb
          submitAction={handleForgotPasswordSubmit}
          serverValidation={requestPasswordChange?.error?.validationErrors}
          validationSchema={schema}
        />
      </Web>
      <Ionic>
        <ForgotPasswordFormIonic
          submitAction={handleForgotPasswordSubmit}
          serverValidation={requestPasswordChange?.error?.validationErrors}
          validationSchema={schema}
        />
      </Ionic>
    </UXComponent>
  );
}
