import * as yup from 'yup';

export const schema = yup.object().shape({
  email: yup
    .string()
    .required('validation.requiredErrorLabel'),
  password: yup
    .string()
    .required('validation.requiredErrorLabel'),
});
