import { yupResolver } from '@hookform/resolvers/yup';
import { IonInput, IonList } from '@ionic/react';
import { handleFormDataReset, handleServerHookForm } from '@napa/react-core';
import HookInput from 'components/baseElements/formControls/HookInput';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { User } from 'types/users';

interface UserProfileFormProperties {
  submitAction?: (data: any) => any;
  dataIsLoading?: boolean;
  serverValidation?: any;
  validationSchema?: any;
  user?: User;
}

export default function UserProfileFormWeb(props: UserProfileFormProperties): JSX.Element {
  const {
    submitAction = () => {
      return;
    },
    serverValidation,
    validationSchema,
    user,
  } = props;

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  useEffect(() => {
    handleFormDataReset(user, form.reset);
  }, [user, form.reset]);

  return (
    <>
      <form onSubmit={form.handleSubmit(submitAction)} noValidate>
        <IonList lines="full">
          <HookInput form={form} labelText="userProfileForm.emailLabel" name="email">
            <IonInput disabled={true} />
          </HookInput>
        </IonList>
      </form>
    </>
  );
}
