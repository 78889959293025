import { faCalculator, faLock, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrapeButton } from 'napa-react-core';
import { Box, Flex, Header, Paragraph } from '@napa/grape-ui-react';
import React from 'react';
import styled from 'styled-components';

const ServicesOfferedSection = styled('section')`
  text-align: center;
  color: #801747;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fff;
`;

const ServicesBox = styled(Box)`
  ${Paragraph} {
    color: rgb(122, 122, 122);
  }
`;

ServicesBox.defaultProps = {
  maxWidth: 375,
  px: '5px',
  pt: '35px',
  width: [1, 375]
}

const ServicesIconBox = styled(Box)`
  background-color: #801747;
  color: #ffffff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  font-size: 42pt;
  padding-top: 12px;
  margin-bottom: 20px;
`;

const ServicesOffered: React.FC = () => {
  return (
    <ServicesOfferedSection>
      <Header.h2 style={{ fontSize: '39px', fontWeight: 'bold' }}>SERVICES</Header.h2>
      <Header.h5>Securely store, manage, and transport your valuable wine collection</Header.h5>
      <GrapeButton href="/static-content/StorageAgreement.pdf">
        Download Storage Agreement
      </GrapeButton>
      <Flex alignItems={['center', 'flex-start']} flexDirection={['column', 'row']} justifyContent="center">
        <ServicesBox>
          <Flex flexDirection="column">
            <ServicesIconBox alignSelf="center">
              <FontAwesomeIcon icon={faLock} />
            </ServicesIconBox>
            <Box alignSelf="center">
              <Header.h6>MANAGED SECURITY SERVICES</Header.h6>
              <Paragraph>
                Secure climate controlled storage, with CCTV systems, monitored door access, alarm
                monitoring stations, offsite temperature monitoring systems, and much more
              </Paragraph>
            </Box>
          </Flex>
        </ServicesBox>
        <ServicesBox>
          <Flex flexDirection="column">
            <ServicesIconBox alignSelf="center">
              <FontAwesomeIcon icon={faCalculator} />
            </ServicesIconBox>
            <Box alignSelf="center">
              <Header.h6>INVENTORY MANAGEMENT</Header.h6>
              <Paragraph>
                Track volume, performance, and valuation of your wine portfolio with a custom
                database
              </Paragraph>
            </Box>
          </Flex>
        </ServicesBox>
        <ServicesBox>
          <Flex flexDirection="column">
            <ServicesIconBox alignSelf="center">
              <FontAwesomeIcon icon={faTruckMoving} />
            </ServicesIconBox>
            <Box alignSelf="center">
              <Header.h6>CELLAR LOGISTICS</Header.h6>
              <Paragraph>
                Avoid hassle and worry by having us organize, pack, transport, and unload any size
                wine collection
              </Paragraph>
            </Box>
          </Flex>
        </ServicesBox>
      </Flex>
    </ServicesOfferedSection>
  );
};

export default ServicesOffered;
